(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/auth0_login_page.html",
    "<div class=\"login\">\n" +
    "    <div class=\"body\">\n" +
    "        <div class=\"content\">\n" +
    "            <h1>Auth0 Login</h1>\n" +
    "            <div>\n" +
    "                <img src=\"images/builder_logo.png\" width=\"240\" />\n" +
    "              </div>\n" +
    "            <button class=\"action\" ng-click=\"onClick()\">Sign In</button>\n" +
    "            <a href=\"#!/configurations\">\n" +
    "                Return\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/login.html",
    "<div class=\"btf-modal login\">\n" +
    "  <h4 class=\"title\">Login</h4>\n" +
    "  <div class=\"body login-form\">\n" +
    "    <div class=\"content\">\n" +
    "      <div>\n" +
    "        <img src=\"images/builder_logo.png\" width=\"240\" />\n" +
    "      </div>\n" +
    "      <div ng-class=\"{'error-box':errorMessage}\">\n" +
    "        <div ng-class=\"{'error-info':errorMessage}\">\n" +
    "          <h3 ng-if=\"errorTitle\">{{errorTitle}}</h3>\n" +
    "          <p ng-if=\"errorMessage\">{{errorMessage}}</p>\n" +
    "          <a href=\"{{url}}\">\n" +
    "            <button class=\"action\">{{errorButton || 'Sign In Securely'}}</button>\n" +
    "          </a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/configuration.html",
    "<div class=\"layout\">\n" +
    "  <div class=\"layout-drawer primary-nav\">\n" +
    "    <button\n" +
    "      class=\"fa layout-button layout-button-main\"\n" +
    "      ng-click=\"toggleExpandedSideNav()\"\n" +
    "      alt=\"toggle side nav\"\n" +
    "    >\n" +
    "      <span class=\"fa fa-chevron-right\" ng-if=\"toggles['expanded-side-nav']\"></span>\n" +
    "      <span class=\"fa fa-chevron-left\" ng-if=\"!toggles['expanded-side-nav']\"></span>\n" +
    "    </button>\n" +
    "    <ng-include src=\"'views/configuration/side-nav/primary-nav.html'\"></ng-include>\n" +
    "  </div>\n" +
    "  <div class=\"layout-drawer configuration-nav\">\n" +
    "    <ng-include\n" +
    "      src=\"'views/configuration/side-nav/configuration-nav.html'\"\n" +
    "      ng-controller=\"ModulesCtrl\"\n" +
    "    >\n" +
    "    </ng-include>\n" +
    "  </div>\n" +
    "  <div\n" +
    "    class=\"layout-main-panel\"\n" +
    "    ui-view=\"detail\"\n" +
    "    ng-class=\"{'upload-bg': $state.includes('configuration.module.unit.upload')}\"\n" +
    "  >\n" +
    "    >\n" +
    "  </div>\n" +
    "  <div sidebar active=\"sidebarActive\" on-close=\"hideSidebar()\">\n" +
    "    <div\n" +
    "      tabs=\"[\n" +
    "          {\n" +
    "            name: 'Editor',\n" +
    "            template: 'views/editor/editor-tab.html'\n" +
    "          }\n" +
    "        ]\"\n" +
    "      change-tab=\"changeTab\"\n" +
    "      active-tab=\"activeTab\"\n" +
    "    ></div>\n" +
    "  </div>\n" +
    "  <div sidebar active=\"encyclopediaActive\" on-close=\"hideEncyclopedia()\">\n" +
    "    <encyclopedia field-binding=\"encyclopediaField\"></encyclopedia>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/edit-configuration.html",
    "<div class=\"plain-form form-wrapper\" ng-form=\"form\" ng-show=\"userCan('edit stuff')\">\n" +
    "  <h3 ng-if=\"!editing && !duplicating\">Create a new configuration for {{customer.name}}</h3>\n" +
    "  <h3 ng-if=\"!editing && duplicating\">Duplicate {{configToDuplicate.name}} for {{customer.name}}</h3>\n" +
    "  <h3 ng-if=\"editing\">Edit {{configuration.name}} for {{customer.name}}</h3>\n" +
    "\n" +
    "  <a ng-if=\"previousState.name != 'configurations'\" ui-sref=\"customers.list\">Back to the customers list</a>\n" +
    "  <a ng-if=\"previousState.name == 'configurations'\" ui-sref=\"configurations\">Back to the configurations list</a>\n" +
    "\n" +
    "  <div class=\"group\">\n" +
    "    <div class=\"row\" ng-hide=\"user.role==='customer'\">\n" +
    "      <label>ALEX Package</label>\n" +
    "      <select ng-model=\"configuration.customerTier\" name=\"customerTier\" ng-required=\"user.role!=='customer'\">\n" +
    "        <option ng-repeat=\"(key, value) in availableTiers\" ng-selected=\"{{configuration.customerTier === key}}\"\n" +
    "          value=\"{{value}}\">\n" +
    "          {{value}}\n" +
    "        </option>\n" +
    "      </select>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"!duplicating\">\n" +
    "      <label>Products</label>\n" +
    "      <div ng-repeat=\"product in products\">\n" +
    "        <input type=\"checkbox\" ng-model=\"product.isChecked\" value=\"{{product.id}}\" ng-required=\"isOptionsRequired()\" />\n" +
    "        <span>{{product.name}}</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"showPlanYear()\">\n" +
    "      <label>Plan Year</label>\n" +
    "      <select ng-model=\"configuration.planYear\" name=\"planYear\" ng-required=\"true\" validate-url-slug=\"{{uniqueUrls}}\">\n" +
    "        <option value=\"2021\">2021</option>\n" +
    "        <option value=\"2022\">2022</option>\n" +
    "        <option value=\"2023\">2023</option>\n" +
    "        <option value=\"2024\">2024</option>\n" +
    "        <option value=\"2025\">2025</option>\n" +
    "        <option value=\"2026\">2026</option>\n" +
    "        <option value=\"2027\">2027</option>\n" +
    "      </select>\n" +
    "      <div class=\"error-messages-displayed\" ng-messages=\"form.planYear.$error\">\n" +
    "        <div class=\"error-messages-displayed\" ng-show=\"form.planYear.$error.validateUrlSlug\">This client already has a\n" +
    "          configuration with that plan year. Make sure you select the intended plan year or reach out to\n" +
    "          resellers@jellyvision.com if you need support.</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"isEmployee\">\n" +
    "      <label>Name</label>\n" +
    "      <span class=\"field-text min-width-10\" text name=\"name\" ng-required=\"false\" ng-model=\"configuration.name\"></span>\n" +
    "      <span class=\"clarification\">\n" +
    "        Optional, but helps you tell your configurations apart if you're building more than one for a single customer.\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"duplicating && configToDuplicateBCModule && configToDuplicateBCModule.active && isEmployee\">\n" +
    "      <label>Benefits Counselor URL</label>\n" +
    "      <span class=\"field-text min-width-10\" text name=\"urlPath\" ng-required=\"true\" ng-model=\"configuration.urlPath\"\n" +
    "        validate-url-slug=\"{{uniqueUrls}}\">\n" +
    "      </span>\n" +
    "      <span class=\"clarification\">\n" +
    "        This will complete the URL for this specific ALEX experience. It does not need to match the configuration name.\n" +
    "      </span>\n" +
    "      <span class=\"note\" ng-if=\"configuration.urlPath\">\n" +
    "        The full URL for this experience will be\n" +
    "        https://www.myalex.com/{{customer.customerPath}}/{{configuration.urlPath}}.\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.urlPath.$error\">\n" +
    "        <div class=\"error-message\" ng-message=\"slugValid\" ng-show=\"!form.urlPath.$error.slugValid\">Please use only\n" +
    "          letters, numbers, hyphens, or underscores in the customer's URL.</div>\n" +
    "        <div class=\"error-message\" ng-message=\"slugAvailable\"\n" +
    "          ng-show=\"form.urlPath.$error.slugAvailable && !form.urlPath.$error.slugValid\">Sorry, that URL is already in\n" +
    "          use. It must be unique to this customer.</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"duplicating && configToDuplicateBCModule && configToDuplicateBCModule.active\">\n" +
    "      <label>Eligible Employees</label>\n" +
    "      <span class=\"field-text min-width-10\" text name=\"eligibleEmployees\" ng-required=\"true\"\n" +
    "        ng-model=\"configuration.eligibleEmployees\" number=\"true\">\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.eligibleEmployees.$error\">\n" +
    "        <div ng-message=\"number\">This must be a number.</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"duplicating\">\n" +
    "      <label>Shift dates forward one year</label>\n" +
    "      <input type=\"checkbox\" name=\"incrementYears\" ng-model=\"configuration.incrementYears\">\n" +
    "      </input>\n" +
    "      <span class=\"clarification\">\n" +
    "        If checked, each date (plan year, FSA dates, etc.) will be advanced one year in the new configuration.\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"group\">\n" +
    "    <label></label>\n" +
    "    <button ng-if=\"!editing && duplicating\" ng-disabled=\"!form.$valid\" class=\"action\"\n" +
    "      ng-click=\"duplicateConfiguration()\">Finish and start configuring details</button>\n" +
    "    <button ng-if=\"!editing && !duplicating\" ng-disabled=\"!form.$valid\" class=\"action\"\n" +
    "      ng-click=\"createConfiguration()\">Finish and start configuring plan details</button>\n" +
    "    <button ng-if=\"editing\" class=\"action\" ui-sref=\"configurations\">Back to the configurations list</button>\n" +
    "  </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/eligibility.html",
    "<div class=\"eligibility-question-page\">\n" +
    "  <div class=\"page-title\">\n" +
    "    <h1>\n" +
    "      <span class=\"customer-title\" ng-if=\"customer.name\">\n" +
    "        {{customer.name}}\n" +
    "      </span>\n" +
    "      <span ng-if=\"customer.name\">&raquo;</span>\n" +
    "      <span class=\"module-title\">Eligibility Questions</span>\n" +
    "    </h1>\n" +
    "  </div>\n" +
    "  <form name=\"form\" class=\"form\" novalidate>\n" +
    "    <div class=\"question-section-container\">\n" +
    "      <h2>Questions ALEX will ask</h2>\n" +
    "      <p>When a benefit isn’t available to all employees, ALEX will ask users eligibility questions to figure out what\n" +
    "        content to display.</p>\n" +
    "      <p>Questions shown here have been added as eligibility requirements to individual benefits.</p>\n" +
    "      <div\n" +
    "        class=\"question\"\n" +
    "        ng-repeat=\"question in questions | filter:{inUse:true} as usedQuestions\"\n" +
    "        ng-if=\"!applying\">\n" +
    "        <div class=\"question-buttons\"\n" +
    "          ng-hide=\"!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier))\">\n" +
    "          <button class=\"invert\" ng-disabled=\"question.in_use\" ng-click=\"deleteQuestion(question)\">remove</button>\n" +
    "        </div>\n" +
    "        <div\n" +
    "          question=\"question\"\n" +
    "          configuration=\"configuration\"\n" +
    "          user=\"user\"></div>\n" +
    "      </div>\n" +
    "      <div ng-if=\"!usedQuestions.length\" class=\"empty-message\">\n" +
    "        <span>Looks like you haven't added eligibility requirements to any benefits!</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"question-section-container\">\n" +
    "      <h2>Questions ALEX won't ask... yet</h2>\n" +
    "      <p>Below are questions that can be used as eligibility criteria, but aren’t currently added to any benefits.</p>\n" +
    "\n" +
    "      <div class=\"question-subsection-container\">\n" +
    "        <h3>Pre-written questions</h3>\n" +
    "        <p>Commonly asked questions – handcrafted with love for you to use</p>\n" +
    "        <div class=\"question\" ng-repeat=\"question in questions | filter:{inUse:false,key:''} as prewrittenQuestions\"\n" +
    "          ng-if=\"!applying\">\n" +
    "          <div class=\"question-buttons\"\n" +
    "            ng-hide=\"!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier))\">\n" +
    "            <button class=\"invert\" ng-disabled=\"question.in_use\" ng-click=\"deleteQuestion(question)\">remove</button>\n" +
    "          </div>\n" +
    "          <div question=\"question\" configuration=\"configuration\" user=\"user\"></div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"!prewrittenQuestions.length\" class=\"empty-message\">\n" +
    "          <span>Looks like you’re using all the pre-written questions.</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"question-subsection-container\">\n" +
    "        <h3>Custom questions</h3>\n" +
    "        <p>Pre-written questions don’t get you what you need? Create your own right here.</p>\n" +
    "        <div\n" +
    "          class=\"question custom-question\"\n" +
    "          ng-repeat=\"question in questions | filter:{inUse:false,key:null} as customQuestions\"\n" +
    "          ng-if=\"!applying\">\n" +
    "          <div class=\"question-buttons\" ng-hide=\"!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier))\">\n" +
    "            <button class=\"invert\" ng-disabled=\"question.in_use\" ng-click=\"deleteQuestion(question)\">remove</button>\n" +
    "            <!--button type=\"button\" class=\"invert\" ng-disabled=\"disableReorder('up')\" ng-click=\"reorderRule('up')\">move up</button>\n" +
    "        <button type=\"button\" class=\"invert\" ng-disabled=\"disableReorder('down')\" ng-click=\"reorderRule('down')\">move down</button-->\n" +
    "          </div>\n" +
    "          <div\n" +
    "            question=\"question\"\n" +
    "            configuration=\"configuration\"\n" +
    "            user=\"user\"></div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <button type=\"button\" ng-click=\"addQuestion()\" ng-show=\"userCan('edit this configuration', { configuration: configuration})\">\n" +
    "        + Create new question\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </form>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/module.html",
    "<div>\n" +
    "  <div ui-view=\"form\" class=\"form\"></div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/publishing.html",
    "<div>\n" +
    "  <div class=\"page-title\">\n" +
    "    <h1>\n" +
    "      <span class=\"customer-title\" ng-if=\"customer.name\">\n" +
    "        {{customer.name}}\n" +
    "      </span>\n" +
    "      <span ng-if=\"customer.name && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\">&raquo;</span>\n" +
    "      <span class=\"module-title\" ng-if=\"customer.name && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\">Publishing</span>\n" +
    "    </h1>\n" +
    "  </div>\n" +
    "  <div\n" +
    "    class=\"setup\"\n" +
    "    ng-if=\"user.role === 'employee' || $root.isDIY(configuration.customerTier)\">\n" +
    "    <form name=\"form\" class=\"form-wrapper publishing\">\n" +
    "      <v-accordion class=\"product-nav animate\" multiple>\n" +
    "        <v-pane\n" +
    "          class=\"animate product-nav-group\"\n" +
    "          ng-repeat=\"productSelection in productSelections | orderBy:'name' | filter: { active: true }\"\n" +
    "          expanded=\"product.expanded\">\n" +
    "          <v-pane-header class=\"animate\">\n" +
    "            <div class=\"field-wrapper product-nav-title\">\n" +
    "              <h2>\n" +
    "              {{productSelection.name}}\n" +
    "              </h2>\n" +
    "              <button ng-if=\"user.role === 'employee'\" class=\"link\" ng-click=\"preview(productSelection)\"><span class=\"fa fa-search\"></span> Preview data</button>\n" +
    "            </div>\n" +
    "          </v-pane-header>\n" +
    "          <v-pane-content class=\"animate\">\n" +
    "            <div publish-fields customer=\"customer\" product=\"productSelection.Product\"></div>\n" +
    "            <div class=\"field-wrapper publishing-controls\" ng-if=\"userCan('edit stuff')\">\n" +
    "              <button\n" +
    "                ng-repeat=\"consumer in productSelection.Consumers\"\n" +
    "                ng-show=\"shouldShowConsumer(consumer,productSelection.Product)\"\n" +
    "                ng-disabled =\"{{(!checkProductStatus(productSelections,'Benefits Counselor / ALEX Go')\n" +
    "                && consumerTense(consumer, productSelection.key) ==='Launch'\n" +
    "                && (productSelection.key === 'newhire' || productSelection.key === 'benefits_sneak_peek' )) ? 'true' : 'false'}}\"\n" +
    "                class=\"action\"\n" +
    "                ng-click=\"beginPublishing(productSelection.Product, consumer)\">\n" +
    "                   {{consumerTense(consumer, productSelection.key)}}\n" +
    "              </button>\n" +
    "              <div ng-if=\"!checkProductStatus(productSelections,'Benefits Counselor / ALEX Go')\n" +
    "              && $root.isDIY(configuration.customerTier)\n" +
    "              && (productSelection.key === 'newhire' || productSelection.key === 'benefits_sneak_peek' )\" class=\"enableBCMessage\">BENEFITS COUNSELOR / ALEX GO MUST BE TURNED ON</div>\n" +
    "            </div>\n" +
    "          </v-pane-content>\n" +
    "        </v-pane>\n" +
    "      </v-accordion>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/setup.html",
    "<div>\n" +
    "  <div class=\"page-title\">\n" +
    "    <h1>\n" +
    "    	<span class=\"customer-title\" ng-if=\"customer.name\">\n" +
    "		  	{{customer.name}}\n" +
    "		  </span>\n" +
    "		  <span ng-if=\"customer.name && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\">&raquo;</span>\n" +
    "			<span class=\"module-title\" ng-if=\"customer.name && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\">Products</span>\n" +
    "		</h1>\n" +
    "  </div>\n" +
    "	<div\n" +
    "		class=\"form setup\"\n" +
    "		ng-if=\"user.role === 'employee' || $root.isDIY(configuration.customerTier)\">\n" +
    "		<form name=\"form\" class=\"form-wrapper product-usage\">\n" +
    "			<v-accordion class=\"product-nav animate\" multiple>\n" +
    "		    <v-pane\n" +
    "		    	class=\"animate product-nav-group\"\n" +
    "		    	ng-repeat=\"product in productSelections | orderBy:'name'\"\n" +
    "		    	expanded=\"product.expanded\">\n" +
    "		      <v-pane-header class=\"animate\">\n" +
    "		        <div class=\"product-nav-title\">\n" +
    "		          <input\n" +
    "		            id=\"product_{{product.key}}\"\n" +
    "		            type=\"checkbox\"\n" +
    "		            class=\"checkbox switch\"\n" +
    "		            ng-model=\"product.active\"\n" +
    "		            ng-disabled=\"!userCan('edit this configuration', this)\"\n" +
    "		            ng-change=\"onProductToggle(product)\"\n" +
    "		            name=\"product_{{product.key}}\"\n" +
    "		            ng-click=\"$event.stopPropagation();\">\n" +
    "		          <label\n" +
    "		          	class=\"switch\"\n" +
    "		          	for=\"product_{{product.key}}\"\n" +
    "		          	ng-click=\"$event.stopPropagation()\"></label>\n" +
    "		          {{product.name}}\n" +
    "		        </div>\n" +
    "		      </v-pane-header>\n" +
    "		      <v-pane-content class=\"animate\">\n" +
    "		      	<div class=\"table\">\n" +
    "					    <div>\n" +
    "					      <table>\n" +
    "					        <col>\n" +
    "					        <col>\n" +
    "					        <col>\n" +
    "					        <thead>\n" +
    "					        	<tr>\n" +
    "					        		<th></th>\n" +
    "					        		<th>Module Name</th>\n" +
    "					        		<th>Custom Name</th>\n" +
    "					        	</tr>\n" +
    "					        </thead>\n" +
    "					        <tbody>\n" +
    "					          <tr ng-repeat=\"module in product.definitions | orderBy: product.name === 'Benefits Counselor / ALEX Go' ? 'displayOrder' : 'description'\" ng-hide=\"shouldHideModule(user, module)\">\n" +
    "\n" +
    "					          	<th>\n" +
    "					          		<input\n" +
    "						                id=\"product_{{product.key}}_module_{{module.name}}\"\n" +
    "						                type=\"checkbox\"\n" +
    "														class=\"checkbox\"\n" +
    "						                ng-model=\"module.active\"\n" +
    "						                ng-disabled=\"configuration.locked || (product.active && module.active && module.required) || !userCan('edit stuff') || !product.active\"\n" +
    "						                ng-change=\"onModuleToggle(module)\"\n" +
    "						                name=\"product_{{product.key}}_module_{{module.name}}\">\n" +
    "					          	</th>\n" +
    "					            <th>\n" +
    "					              <label for=\"product_{{product.key}}_module_{{module.name}}\">\n" +
    "					                {{module.description}}\n" +
    "					              </label>\n" +
    "				                <em ng-if=\"module.required\">\n" +
    "				                 Required\n" +
    "				                </em>\n" +
    "					            </th>\n" +
    "					            <th>\n" +
    "					              <label ng-show=\"!module.renaming && module.title !== module.description\">\n" +
    "					                {{module.title}}\n" +
    "					              </label>\n" +
    "				                <text\n" +
    "													ng-show=\"module.renaming\"\n" +
    "													ng-model=\"module.title\"\n" +
    "													placeholder=\"{{module.description}}\"\n" +
    "													></text>\n" +
    "							          <span>\n" +
    "													<button ng-show=\"module.active && !module.renaming\" ng-click=\"module.renaming = true\">Rename</button>\n" +
    "													<button class=\"invert\" ng-show=\"module.renaming\" ng-click=\"(module.renaming = false) || onModuleUpdate(module)\">Submit</button>\n" +
    "													<button ng-show=\"module.renaming\" ng-click=\"((module.renaming = false) && (module.title = module.originalTitle)) || true\">Cancel</button>\n" +
    "												</span>\n" +
    "					            </th>\n" +
    "					          </tr>\n" +
    "					        </thead>\n" +
    "					      </table>\n" +
    "					    </div>\n" +
    "					  </div>\n" +
    "		      </v-pane-content>\n" +
    "		    </v-pane>\n" +
    "		  </v-accordion>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/unit.html",
    "<div>\n" +
    "  <div class=\"page-title\">\n" +
    "    <h1>\n" +
    "		  <span class=\"customer-title\" ng-if=\"customer.name\">\n" +
    "		  	{{customer.name}}\n" +
    "		  </span>\n" +
    "		  <span ng-if=\"customer.name\">&raquo;</span>\n" +
    "		  <span class=\"module-title\">{{module.title}}</span>\n" +
    "		  <span ng-if=\"unit.name\">&raquo;</span>\n" +
    "		  <span class=\"unit-name\">{{unitDisplayName(unit)}}</span>\n" +
    "		</h1>\n" +
    "  </div>\n" +
    "  <div ui-view=\"form\" class=\"form\"></div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/customers/configurations.html",
    "<div class=\"configurations\">\n" +
    "  <div class=\"page-header greeting_Header\">{{greeting}}</div>\n" +
    "  <div ng-if=\"user.role === 'employee'\">\n" +
    "    <div class=\"page-header config-header-recent\">\n" +
    "      <span>Recently Viewed Configurations</span>\n" +
    "      <span class=\"limit-dropdown\" ng-if=\"recentConfigurationsList.length\">\n" +
    "        <select ng-options=\"val for val in recentConfigLimits\" ng-model=\"recentConfigPagination.limit\" ng-change=\"changeRecentConfigLimit()\" ></select>\n" +
    "        <span>Recent Configurations</span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <configurations-table list=\"recentConfigurationsList\" ng-if=\"recentConfigurationsList.length\" id=\"RECENT_TABLE\"></configurations-table>\n" +
    "    <div ng-if=\"recentConfigurationsList.length && (recentConfigPagination.page <= recentConfigMaxPage && recentConfigMaxPage > 1)\" class=\"pagination-panel clearfix\">\n" +
    "      <button ng-disabled=\"recentConfigPagination.page == recentConfigMaxPage\" style=\"float: right; margin-left: 10px;\" id=\"allConfiguration\" class=\"action\" ng-if=\"!readOnly\" ng-click=\"nextRecentPage()\">\n" +
    "        Next\n" +
    "      </button>\n" +
    "      <span>Page {{recentConfigPagination.page}}/{{recentConfigMaxPage}}</span>\n" +
    "      <button ng-disabled=\"recentConfigPagination.page == 1\" style=\"float: right; margin-left: 10px;\" id=\"allConfiguration\" class=\"action\" ng-if=\"!readOnly\" ng-click=\"previousRecentPage()\">\n" +
    "        Previous\n" +
    "      </button>\n" +
    "    </div>\n" +
    "    <div class=\"searchDataEmpty\" ng-show=\"!recentConfigurationsList.length\">\n" +
    "    No recently viewed configurations. Your top 10 most recent will be displayed here.\n" +
    "  </div>\n" +
    "</div>\n" +
    "    <div class=\"search clearfix\">\n" +
    "      <div class=\"find-header\">Find a configuration</div>\n" +
    "      <input type=\"text\" placeholder=\"Search by customer, name, or ID\" ng-model=\"searchTerm\" ng-change=\"onChangeSearchTerm(searchTerm)\" ng-keydown=\"keyPressed($event, searchTerm)\"/>\n" +
    "      <button class=\"action submitButton\" ng-if=\"!readOnly\" ng-click=\"search(searchTerm, true)\">\n" +
    "        Search\n" +
    "      </button>\n" +
    "    </form>\n" +
    "      <button style=\"float: right; margin-left: 10px;\" id=\"allConfiguration\" ng-if=\"!readOnly\" ng-click=\"viewAllConfiguration()\">\n" +
    "        View All Configurations\n" +
    "      </button>\n" +
    "      <button id=\"newConfiguration\" class=\"action\" ng-if=\"!readOnly\" ng-click=\"createNewConfiguration()\">\n" +
    "        + Create a New Configuration\n" +
    "      </button>\n" +
    "      <div class=\"validation-messages\" ng-messages=\"hasError\" ng-show=\"hasError\">Please enter search terms</div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"configurations.length\" class=\"page-header config-header-search\">\n" +
    "      <span ng-if=\"!viewAllConfig\">Configurations containing \"{{previousKeyword}}\"</span>\n" +
    "      <span ng-if=\"viewAllConfig\">All Configurations</span>\n" +
    "      <span class=\"totalRecords\" ng-show=\"totalRecords\"> {{totalRecords}} results returned.</span>\n" +
    "      <span class=\"limit-dropdown\" ng-if=\"configurations.length\">\n" +
    "        <select ng-options=\"val for val in recentConfigLimits\" ng-model=\"searchPagination.limit\" ng-change=\"changeSearchConfigLimit()\" ></select>\n" +
    "        <span>Configurations</span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <configurations-table style=\"padding-bottom: 1rem\" ng-if=\"configurations.length\" list=\"configurations\" id=\"SEARCH_TABLE\"></configurations-table>\n" +
    "    <div ng-if=\"configurations.length && (searchPagination.page <= maxPage  && maxPage > 1)\" class=\"pagination-panel clearfix\">\n" +
    "      <button ng-disabled=\"searchPagination.page == maxPage\" style=\"float: right; margin-left: 10px;\" id=\"allConfiguration\" class=\"action\" ng-if=\"!readOnly\" ng-click=\"nextSearchPage()\">\n" +
    "        Next\n" +
    "      </button>\n" +
    "      <span>Page {{searchPagination.page}}/{{maxPage}}</span>\n" +
    "      <button ng-disabled=\"searchPagination.page == 1\" style=\"float: right; margin-left: 10px;\" id=\"allConfiguration\" class=\"action\" ng-if=\"!readOnly\" ng-click=\"previousSearchPage()\">\n" +
    "        Previous\n" +
    "      </button>\n" +
    "    </div>\n" +
    "    <div class=\"searchDataEmpty\" ng-show=\"noDataFound\">\n" +
    "      There are no configurations that match <b>\"{{previousKeyword}}\"</b>. Clear your filter, or create a new configuration. \n" +
    "    </div>\n" +
    "  </div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/customers/customer.html",
    "<div class=\"plain-form form-wrapper\" ng-form=\"form\" ng-show=\"userCan('edit stuff')\">\n" +
    "  <h3 ng-if=\"!editing\">Create a new customer</h3>\n" +
    "  <h3 ng-if=\"editing\">Edit {{customer.name}}</h3>\n" +
    "  <a ng-if=\"previousState.name != 'configurations'\" ui-sref=\"customers.list\">Back to the customers list</a>\n" +
    "  <a ng-if=\"previousState.name == 'configurations'\" ui-sref=\"configurations\">Back to the configurations list</a>\n" +
    "\n" +
    "\n" +
    "  <div class=\"group\">\n" +
    "    <div class=\"row\">\n" +
    "      <label>Full Name</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        name=\"name\"\n" +
    "        ng-model=\"customer.name\"\n" +
    "        ng-required=\"true\"\n" +
    "        placeholder=\"customer name\"></span>\n" +
    "      <span class=\"clarification\">\n" +
    "        What's the full name of the company?\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label>Informal Name</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        name=\"informalName\"\n" +
    "        ng-model=\"customer.informalName\"\n" +
    "        maxlength=\"38\"\n" +
    "        ng-required=\"true\"\n" +
    "        placeholder=\"informal name\"></span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.informalName.$error\">\n" +
    "        <div ng-message=\"maxlength\">This can be at most 38 characters.</div>\n" +
    "      </div>\n" +
    "      <span class=\"clarification\">\n" +
    "        What do most employees call the customer? When ALEX puts the name of the customer on screen, he'll use whatever you enter here.\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label>Customer Key</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        ng-required=\"true\"\n" +
    "        ng-disabled=\"editing\"\n" +
    "        name=\"customerKey\"\n" +
    "        ng-model=\"customer.customerKey\"></span>\n" +
    "      <span class=\"clarification\">\n" +
    "        This uniquely identifies the customer. It will be the first part of the URL for all the ALEX experiences configured for the customer.\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.customerKey.$error\">\n" +
    "        <div class=\"error-message\" ng-message=\"slugValid\" ng-show=\"!form.customerKey.$error.slugValid\">Please use only letters, numbers, or hyphens in the customer key.</div>\n" +
    "        <div class=\"error-message\" ng-message=\"slugAvailable\" ng-show=\"!form.customerKey.$error.slugAvailable && form.customerKey.$error.slugValid\">Sorry, that key is already taken. Please try something else.</div>\n" +
    "      </div>\n" +
    "      <div class=\"note\" ng-if=\"!editing\">\n" +
    "        The customer key cannot be changed once you finish creating this customer.\n" +
    "      </div>\n" +
    "      <div class=\"note\" ng-if=\"editing\">\n" +
    "        The customer key cannot be changed.\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label>Customer ALEX URL</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        name=\"customerPath\"\n" +
    "        ng-required=\"false\"\n" +
    "        ng-pattern=\"/^[a-z0-9-]*$/\"\n" +
    "        placeholder=\"\"\n" +
    "        ng-model=\"customer.customerPath\"\n" +
    "        invalid-suffix=\"-,-uat,-staging\"></span>\n" +
    "      <span class=\"clarification\">\n" +
    "        This will be the first part of the URL for all the ALEX experiences configured for the customer. (Max length {{MAX_CUSTOMER_PATH_LENGTH}} characters. {{returnCharsRemaining(form.customerPath.$viewValue.length)}} remaining.)\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.customerPath.$error\">\n" +
    "        <div class=\"error-message\" ng-message=\"slugValid\" ng-show=\"form.customerPath.$error.pattern\">The URL must be lowercase, numerals, or dashes.</div>\n" +
    "        <div class=\"error-message\" ng-show=\"form.customerPath.$error.invalidSuffix\">The URL may not end with '-', '-uat', or '-staging'.</div>\n" +
    "        <div class=\"error-message\" ng-message=\"slugAvailable\" ng-show=\"!form.customerPath.$error.slugAvailable && form.customerPath.$error.slugValid\">Sorry, that URL is already taken. Please try something else.</div>\n" +
    "      </div>\n" +
    "      <div class=\"note\">\n" +
    "        <span>\n" +
    "          This customer's ALEX URLs will begin with https://www.myalex.com/{{customer.customerPath}}.\n" +
    "        </span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"user.role === 'employee'\">\n" +
    "      <label>Partner Key</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        name=\"partnerKey\"\n" +
    "        ng-required=\"false\"\n" +
    "        ng-model=\"customer.partnerKey\"></span>\n" +
    "      <span class=\"clarification\">\n" +
    "        If needed, this will give a reseller or broker access to this customer's configurations.\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label>Customer Website</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        url\n" +
    "        ng-required=\"user.role === 'partner'\"\n" +
    "        name=\"customerUrl\"\n" +
    "        placeholder=\"\"\n" +
    "        ng-model=\"customer.url\"></span>\n" +
    "      <span class=\"clarification small\">\n" +
    "        What is the company website or homepage url?<br/>\n" +
    "        Because many companies have similar names, we use this to identify the company for our records.\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label>Employer Identification Number</label>\n" +
    "      <span\n" +
    "        class=\"field-text min-width-10\"\n" +
    "        text\n" +
    "        ng-pattern=\"/^[0-9-]*$/\"\n" +
    "        name=\"ein\"\n" +
    "        ng-required=\"false\"\n" +
    "        ng-maxlength=\"9\"\n" +
    "        ng-minlength=\"9\"\n" +
    "        ng-model=\"customer.ein\"></span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.ein.$error\">\n" +
    "        <div class=\"error-message\" ng-show=\"form.ein.$error.pattern\">The Employer Identification Number may only be a number.</div>\n" +
    "        <div class=\"error-message\" ng-show=\"!form.ein.$error.pattern && (form.ein.$error.maxlength || form.ein.$error.minlength)\">The Employer Identification Number must be exactly 9 digits.</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"group\" ng-if=\"!editing\">\n" +
    "    <label></label>\n" +
    "    <button ng-disabled=\"!form.$valid\" class=\"action\" ng-click=\"createCustomer(true)\">Start configuring ALEX for this customer</buttom>\n" +
    "    <button ng-disabled=\"!form.$valid\" class=\"action\" ng-click=\"createCustomer(false)\">Create customer and return to the customer list</buttom>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/customers/customers.html",
    "<div class=\"customers\">\n" +
    "  <div class=\"search clearfix\" style=\"min-height: 3.5em;\">\n" +
    "    <form style=\"display: inline-block;\">\n" +
    "    <input type=\"text\" placeholder=\"Search by name or ID\" ng-model=\"searchTerm\" />\n" +
    "    <button class=\"action submitButton\" ng-click=\"search(searchTerm)\">\n" +
    "      Submit\n" +
    "    </button>\n" +
    "  </form>\n" +
    "    <button id=\"newCustomer\" class=\"action\" ng-if=\"user.role !== 'customer'\" ui-sref=\"customers.new\">\n" +
    "      Create a customer\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <span class=\"validation-messages\" ng-messages=\"hasError\" ng-show=\"hasError\">Please enter search terms</span>\n" +
    "  <div class=\"table\" ng-if=\"customers.length\">\n" +
    "    <div class=\"fixed\">\n" +
    "      <table>\n" +
    "        <col>\n" +
    "        <col>\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th class=\"orderable centered\" ng-click=\"applyOrder('id')\">\n" +
    "                ID\n" +
    "                <span ng-if=\"activeOrder === 'id'\" class=\"order\">\n" +
    "                  <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                  <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "                </span>\n" +
    "            </th>\n" +
    "            <th class=\"orderable\" ng-click=\"applyOrder('name')\">\n" +
    "                Customer\n" +
    "                <span ng-if=\"activeOrder === 'name'\" class=\"order\">\n" +
    "                  <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                  <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "                </span>\n" +
    "            </th>\n" +
    "            <th>\n" +
    "              Actions\n" +
    "            </th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "    <div class=\"scroll\">\n" +
    "      <table>\n" +
    "        <col>\n" +
    "        <col>\n" +
    "        <col>\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <!--placeholder so absolute can be done via and css tricks, without any JS execution-->\n" +
    "            <th>&nbsp;</th>\n" +
    "            <th>&nbsp;</th>\n" +
    "            <!--th>&nbsp;</th-->\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"customer in customers | orderBy: orderFunc() : reverse | filter: matches(searchTerm)\" \n" +
    "            class=\"clickable\"\n" +
    "            >\n" +
    "              <td class=\"centered\">\n" +
    "                <a ui-sref=\"customers.edit({ customerId: customer.id })\">\n" +
    "                  {{customer.id}}\n" +
    "                </a>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                <a ui-sref=\"customers.edit({ customerId: customer.id })\">\n" +
    "                  {{customer.name || customer.informal_name}}\n" +
    "                </a>\n" +
    "              </td>\n" +
    "              <td class=\"actions\">\n" +
    "                <button class=\"fa fa-plus\" title=\"create new configuration\" ui-sref=\"customers.newConfiguration({customerId: customer.id})\"></button>\n" +
    "                <button class=\"fa fa-trash\" title=\"delete\" ng-click=\"confirmDeleteCustomer($event, customer)\"></button>\n" +
    "              </td>\n" +
    "          </tr>\n" +
    "        <tbody>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"searchDataEmpty\" ng-if=\"!customers.length\">\n" +
    "    There are no customers to show.\n" +
    "</div>\n" +
    "<div class=\"searchDataEmpty\" ng-if=\"dataNotFound\">\n" +
    "  There are no customers that match <b>\"{{searchKeyword}}\"</b>. Clear your filter, or create a new customer. \n" +
    "</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/customers/page.html",
    "<div ui-view=\"customers\"></div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/comment-box.html",
    "<v-accordion multiple class=\"animate\">\n" +
    "  <v-pane expanded=\"isExpanded\" class=\"animate\">\n" +
    "  	<v-pane-header class=\"animate\">\n" +
    "    </v-pane-header>\n" +
    "    <v-pane-content class=\"animate\">\n" +
    "      <span class=\"close-comments\" ng-click=\"closeBox()\"><span>Close</span></span>\n" +
    "      <div class=\"comment\" ng-class=\"$odd ? 'odd-comment' : 'even-comment'\" ng-repeat=\"comment in comments |  filter: {resolved: false} | orderBy: 'createdAt'\">\n" +
    "        <div class=\"comment-data\">\n" +
    "        	<span class=\"comment-author\">{{comment.author}}</span>\n" +
    "          <span class=\"comment-date\">{{comment.updatedAt | date: \"EEE, MMM d, y, h:mm a\"}}</span>\n" +
    "          <span class=\"comment-date\" ng-if=\"comment.updatedAt !== comment.createdAt\">(edited)</span>\n" +
    "          <span class=\"fa fa-pencil\" ng-if=\"$root.user.email === comment.author && !comment.resolved\" ng-click=\"comment.editing = !comment.editing\"><span>Edit</span></span>\n" +
    "          <span class=\"resolve-status\" ng-click=\"toggleResolved(comment)\">Resolve</span>\n" +
    "          <span class=\"fa fa-trash\" ng-if=\"$root.user.email === comment.author\" ng-click=\"deleteComment(comment)\">\n" +
    "          </span>\n" +
    "        </div>\n" +
    "        <ng-form name=\"commentForm\" class=\"edit-comment\" isolate-form>\n" +
    "          <textarea ng-required=\"true\" ng-if=\"comment.editing\" ng-model=\"comment.text\"></textarea>\n" +
    "          <span ng-if=\"!comment.editing\">{{comment.text}}</span>\n" +
    "          <button ng-if=\"comment.editing\" ng-disabled=\"commentForm.$invalid\" class=\"comments-button action\" ng-click=\"saveEdits(comment)\">Save Edits</button>\n" +
    "        </ng-form>\n" +
    "      </div>\n" +
    "      <ng-form name=\"commentForm\" class=\"edit-comment\" isolate-form>\n" +
    "        <textarea ng-required=\"true\" ng-model=\"newComment.text\"></textarea>\n" +
    "        <button ng-disabled=\"commentForm.$invalid\" class=\"comments-button action\" ng-click=\"addComment(newComment)\">Add Comment</button>\n" +
    "      </ng-form>\n" +
    "      <div class=\"expandable\" ng-if=\"(comments | filter: { resolved: true }).length > 0\">\n" +
    "        <div ng-click=\"active = !active\" ng-class=\"{'heading-collapsed': active, 'heading-expanded': !active}\">\n" +
    "          <span>{{(!active ? '+' : '-') + ' Resolved Comments'}}</span>\n" +
    "        </div>\n" +
    "        <div ng-hide=\"!active\">\n" +
    "          <div class=\"comment resolved-comment\" ng-repeat=\"comment in comments | filter: { resolved: true }\">\n" +
    "            <div class=\"comment-data resolved\">\n" +
    "              <span class=\"comment-author\">{{comment.author}}</span>\n" +
    "              <span class=\"comment-date\">{{comment.updatedAt}}</span>\n" +
    "              <span class=\"comment-date\" ng-if=\"comment.updatedAt !== comment.createdAt\">(edited)</span>\n" +
    "              <span class=\"resolve-status resolved\" ng-click=\"toggleResolved(comment)\">Reopen</span>\n" +
    "              <span class=\"fa fa-trash resolved\" ng-if=\"$root.user.email === comment.author\" ng-click=\"deleteComment(comment)\"></span>\n" +
    "            </div>\n" +
    "            <ng-form name=\"commentForm\" class=\"edit-comment\" isolate-form>\n" +
    "              <span>{{comment.text}}</span>\n" +
    "            </ng-form>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </v-pane-content>\n" +
    "  </v-pane>\n" +
    "</v-accordion>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/configurations-table.html",
    "<div class=\"table\">\n" +
    "  <div class=\"scroll\">\n" +
    "    <table>\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('id')}\" class=\"centered\" ng-click=\"applyOrder('id')\">\n" +
    "              <span>ID</span>\n" +
    "              <span ng-if=\"activeOrder === 'id'\" class=\"order\">\n" +
    "                <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "              </span>\n" +
    "          </th>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('customer_name')}\" ng-click=\"applyOrder('customer_name')\">\n" +
    "              <span>Customer</span>\n" +
    "              <span ng-if=\"activeOrder === 'customer_name'\" class=\"order\">\n" +
    "                <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "              </span>\n" +
    "          </th>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('name')}\" ng-click=\"applyOrder('name')\">\n" +
    "              <span>Name</span>\n" +
    "              <span ng-if=\"activeOrder === 'name'\" class=\"order\">\n" +
    "                <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "              </span>\n" +
    "          </th>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('plan_year')}\" ng-click=\"applyOrder('plan_year')\">\n" +
    "            <span>Plan Year</span>\n" +
    "            <span ng-if=\"activeOrder === 'plan_year'\" class=\"order\">\n" +
    "              <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "              <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "            </span>\n" +
    "        </th>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('customer_tier')}\" ng-click=\"applyOrder('customer_tier')\">\n" +
    "              <span>ALEX Package</span>\n" +
    "              <span ng-if=\"activeOrder === 'customer_tier'\" class=\"order\">\n" +
    "                <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "                <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "              </span>\n" +
    "          </th>\n" +
    "          <th ng-class=\"{'orderable' : sortableColumns.includes('expiration_date')}\" ng-click=\"applyOrder('expiration_date')\">\n" +
    "            <span>Expiration Date</span>\n" +
    "            <span ng-if=\"activeOrder === 'expiration_date'\" class=\"order\">\n" +
    "              <span ng-if=\"!reverse\" class=\"fa fa-caret-down\"></span>\n" +
    "              <span ng-if=\"reverse\" class=\"fa fa-caret-up\"></span>\n" +
    "            </span>\n" +
    "        </th>\n" +
    "          <th ng-if=\"user.role === 'partner'\">\n" +
    "            Expiration Date\n" +
    "          </th>\n" +
    "          <th class=\"centered\">\n" +
    "              Actions\n" +
    "          </th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr\n" +
    "        ng-repeat=\"configuration in configurations\"\n" +
    "        class=\"clickable\"\n" +
    "          title=\"click to edit\"\n" +
    "          ng-click=\"addConfigInCookie(configuration.id)\">\n" +
    "            <td class=\"centered\">\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\">\n" +
    "                {{configuration.id}}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\">\n" +
    "                {{configuration.customer.name || configuration.customer.informal_name}}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\">\n" +
    "                {{configuration.name}}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\">\n" +
    "                {{configuration.planYear}}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\">\n" +
    "                {{configuration.customerTier}}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <a ui-sref=\"configuration({ configurationId: configuration.id })\" ng-if=\"$root.isDIY(configuration.customerTier)\" ng-class=\"{expired: expired(configuration)}\">\n" +
    "                  {{configuration.expirationDate}}\n" +
    "              </a>\n" +
    "              <a class=\"renew\" title=\"click to renew\" ng-if=\"user.role !== 'employee' && user.role !== 'customer' && $root.isDIY(configuration.customerTier) && aboutToExpire(configuration)\" ng-click=\"renewConfiguration($event, configuration, configuration.customer)\">Start Renewal</a>\n" +
    "            </td>\n" +
    "            <td class=\"actions\">\n" +
    "              <button class=\"locked\">\n" +
    "                <i class=\"fa configLocked\" ng-if=\"configuration.locked\" ng-class=\"{'fa-lock': configuration.locked == '1'}\"></i>\n" +
    "                <i class=\"fa\" ng-if=\"!configuration.locked\" ng-class=\"{'fa-unlock-alt': configuration.locked != '1'}\"></i>\n" +
    "              </button>\n" +
    "              <button class=\"duplicate\" title=\"duplicate\" ng-click=\"duplicateConfiguration($event, configuration)\"><i class=\"fa fa-clone\"></i></button>\n" +
    "              <button class=\"fa fa-trash\" title=\"delete\" ng-if=\"$root.user.role === 'employee'\" ng-disabled=\"!userCan('edit this configuration', this)\" ng-click=\"confirmDeleteConfiguration($event, configuration, configuration.customer)\"></button>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "      <tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/encyclopedia.html",
    "<div class=\"encyclopedia-content\">\n" +
    "    <div class=\"field-display-text\">\n" +
    "        <span class=\"fa fa-question-circle-o\"></span>\n" +
    "        <span >{{ $ctrl.formattedDisplayText() }}</span>\n" +
    "    </div>\n" +
    "    <div ng-if=\"$ctrl.loading\">Loading...</div>\n" +
    "    <div class=\"s3-content\" ng-if=\"!$ctrl.loading\" bind-html-compile=\"$ctrl.encyclopediaContent\"></div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/extra-menu.html",
    "<div class=\"extra-modal\">\n" +
    "	<span class=\"fa fa-ellipsis-h\" ng-click=\"showMenu($event)\" ng-if=\"!collectionName\"></span>\n" +
    "	<span class=\"collection-import\" ng-click=\"showMenu($event)\" ng-if=\"collectionName\">import</span>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/field.html",
    "<!-- todo: this file isn't actually used see todo in field.js -->\n" +
    "<div \n" +
    "	class=\"field-wrapper {{definition.type}} {{definition.attributes.class}}\"\n" +
    "	field-name=\"{{definition.name}}\"\n" +
    "	ng-form=\"{{definition.name}}\"\n" +
    "	error-messages=\"{{definition.name}}\"\n" +
    "	ng-show=\"definition.attributes.hiddenToUI\"\n" +
    "	ng-transclude\n" +
    "	>\n" +
    "	<div field-history />\n" +
    "	<div help-text></div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/help-text.html",
    "<div\n" +
    "    class=\"has-help-text aside\"\n" +
    "    ng-class=\"{showing: $ctrl.showing}\"\n" +
    "    ng-click=\"$ctrl.toggleToolTip($event)\"\n" +
    "    on-document-click=\"$ctrl.hideToolTip()\">\n" +
    "    <div ng-click=\"$event.stopPropagation()\" class=\"help-help-text\">\n" +
    "        <div ng-bind-html=\"$ctrl.helpTextValue\"></div>\n" +
    "        <div>\n" +
    "            <a\n" +
    "                ng-if=\"$ctrl.definition.encyclopediaId\"\n" +
    "                ng-click=\"$ctrl.hideToolTip(); $root.showEncyclopedia($ctrl.definition)\">\n" +
    "                See an example from ALEX\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/section-wrapper.html",
    "<div class=\"section-wrapper\">\n" +
    "	<h3>{{section.definition.display_text}}</h3>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/sidebar.html",
    "<div class=\"sidebar\">\n" +
    "  <div class=\"sidebar-container\">\n" +
    "    <div class=\"sidebar-title\" ng-click=\"close()\">\n" +
    "      <div class=\"fa fa-times\"></div>\n" +
    "    </div>\n" +
    "    <div class=\"sidebar-content\" ng-transclude></div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/tabs.html",
    "<div class=\"tabs\">\n" +
    "  <ul class=\"tabs-header\">\n" +
    "    <li \n" +
    "      ng-repeat=\"tab in tabs\" \n" +
    "      ng-class=\"{active: _activeTab.name === tab.name}\"\n" +
    "      ng-click=\"_changeTab(tab.name)\"\n" +
    "      ng-if=\"tab._visible\">\n" +
    "      <a>{{tab.name}}</a>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "  <div class=\"tab-content\"></div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/unit-wrapper.html",
    "<div class=\"unit-wrapper\">\n" +
    "	<h3>{{unitDisplayName(unit)}}</h3>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/editor/editor-tab.html",
    "<editor schema=\"schema\" definition=\"activeField.definition\"></editor>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/editor/editor.html",
    "<div class=\"editor\" ng-if=\"schema && definition\">\n" +
    "	<div class=\"preview\">\n" +
    "		<h4>\n" +
    "			Preview\n" +
    "		</h4>\n" +
    "		<div class=\"field-preview\" field=\"definition\"></div>\n" +
    "	</div>\n" +
    "	<json-editor class=\"json-editor\" schema=\"schema\" startval=\"definition\" buttons-controller=\"JSONEditorCtrl\" on-change=\"onChange($editorValue)\">\n" +
    "		<div class=\"copy-button\">\n" +
    "			<button class=\"button tiny json-editor-btn-copy\" ng-click=\"copyToClipboard($event)\"><i class=\"fa fa-copy\"></i> Copy JSON to Clipboard</button>\n" +
    "			<span class=\"success\" ng-class=\"{'success-done': copied}\">Success</span>\n" +
    "			<span class=\"error\" ng-if=\"error\">Something went wrong</span>\n" +
    "		</div>\n" +
    "	</json-editor>\n" +
    "</div> ");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/editor/page.html",
    "<div ui-view=\"editor\" class=\"editor-page\">\n" +
    "	<editor schema=\"schema\" definition=\"definition\"></editor>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/create-configuration-modal.html",
    "<div class=\"btf-modal create-configuration\">\n" +
    "  <h4 class=\"title\" ng-if=\"!duplicating\">Create a new configuration</h4>\n" +
    "  <h4 class=\"title\" ng-if=\"duplicating\">Duplicate {{configuration.name}}</h4>\n" +
    "  <div class=\"body\">\n" +
    "    <div>\n" +
    "      <input type=\"radio\" ng-model=\"form.customerType\" name=\"customerType\" value=\"new\"/>\n" +
    "      <label>New Customer</label>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "      <input type=\"radio\" ng-model=\"form.customerType\" name=\"customerType\" value=\"existing\"/>\n" +
    "      <label>Existing Customer</label>\n" +
    "    </div>\n" +
    "    <div ng-if=\"form.customerType=='existing'\">\n" +
    "      <input type=\"text\" placeholder=\"filter by customer or ID\" ng-model=\"form.searchTerm\" />\n" +
    "      <div ng-if=\"form.searchTerm\">\n" +
    "        <div ng-repeat=\"customer in customers | filter: matches(form.searchTerm) | limitTo:10\" >\n" +
    "          <input ng-model=\"form.selectedCustomerId\" name=\"selectedCustomerId\" type=\"radio\" ng-value=\"customer.id\"/>\n" +
    "          <label>{{customer.name || customer.informal_name}}</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"buttons\">\n" +
    "    <button class=\"action\" ng-if=\"form.customerType == 'new'\" ng-click=\"close()\" ui-sref=\"customers.new\">Create a customer first</button>\n" +
    "    <button\n" +
    "      class=\"action\"\n" +
    "      ng-if=\"form.customerType != 'new' && !duplicating\"\n" +
    "      ng-click=\"close()\"\n" +
    "      ng-disabled=\"!form.selectedCustomerId\"\n" +
    "      ui-sref=\"customers.newConfiguration({customerId: form.selectedCustomerId})\">\n" +
    "      Create a configuration\n" +
    "    </button>\n" +
    "    <button\n" +
    "      class=\"action\"\n" +
    "      ng-if=\"form.customerType != 'new' && duplicating\"\n" +
    "      ng-click=\"close()\"\n" +
    "      ng-disabled=\"!form.selectedCustomerId\"\n" +
    "      ui-sref=\"customers.duplicateConfiguration({customerId: form.selectedCustomerId, configurationId: configuration.id})\">\n" +
    "      Duplicate configuration\n" +
    "    </button>\n" +
    "    <button class=\"action\" ng-click=\"close()\">Nevermind</button>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/delete-modal-popup.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">{{headerText}}</h4>\n" +
    "	<div class=\"body\">{{text}}</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\" ng-hide=\"!cancelText\">{{cancelText}}</button>\n" +
    "		<button ng-click=\"ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/error.html",
    "<div class=\"btf-modal\">\n" +
    "  <h4 class=\"title\">Something went wrong</h4>\n" +
    "  <div class=\"body\">\n" +
    "    <div class=\"bug-image\">\n" +
    "      <img src = \"/images/error-img.png\"/>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "      Oops! Looks like we're having some trouble processing that request. Try reloading, or heading back to Builder's homepage.\n" +
    "    </div>\n" +
    "    <div ng-if=\"message\" class=\"error\" ng-bind-html=\"message\"></div>\n" +
    "  </div>\n" +
    "  <div class=\"buttons\">\n" +
    "    <a href=\"/\" class=\"cancel link\" ng-click=\"_cancel()\">Load Builder homepage</a>\n" +
    "    <button ng-click=\"_ok()\">Reload the page</button>\n" +
    "  </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/general-modal.html",
    "<div class=\"btf-modal\">\n" +
    "    <h4 class=\"title\">{{headerText}}</h4>\n" +
    "    <div class=\"body\" ng-bind-html=\"text\"></div>\n" +
    "    <div class=\"buttons\">\n" +
    "        <button class=\"cancel link\" ng-click=\"_cancel()\" ng-hide=\"!cancelText\">{{cancelText}}</button>\n" +
    "        <button ng-click=\"_ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "    </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/invoicing-modal.html",
    "<div class=\"btf-modal\">\n" +
    "	<!-- since we only launch one product at a time, just pick the first item's description for now -->\n" +
    "	<h4 class=\"title\">{{previouslyLaunched ? 'Relaunching' : 'Launching'}} ALEX</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<div class=\"head\">\n" +
    "			You are about to {{previouslyLaunched ? 'relaunch' : 'launch'}} ALEX for {{customerName}} on the\n" +
    "			{{customerTierDisplay}} ALEX package.\n" +
    "		</div>\n" +
    "		<div ng-if=\"productToPublishKey === 'benefits_sneak_peek'\" class=\"info\">\n" +
    "			Once you {{previouslyLaunched ? 'relaunch' : 'launch'}}, you will receive a link to your {{invoice.items &&\n" +
    "			invoice.items[0].description}} within 2 business days. If you don't receive an email by then, please get in touch\n" +
    "			with resellers@jellyvision.com.\n" +
    "		</div>\n" +
    "		<div class=\"section\">\n" +
    "			<h5 class=\"heading\">\n" +
    "				Expiration\n" +
    "			</h5>\n" +
    "			<div ng-if=\"productToPublishKey !== 'benefits_sneak_peek'\" class=\"info\">\n" +
    "				Your license to ALEX will expire on <b>{{expirationDate}}</b>. That's a year from {{previouslyLaunched ? \"your\n" +
    "				initial launch.\" : \"today's date.\"}}\n" +
    "			</div>\n" +
    "			<div ng-if=\"productToPublishKey === 'benefits_sneak_peek'\" class=\"info\">\n" +
    "				Your license will expire on the same date as this configuration's ALEX license.\n" +
    "			</div>\n" +
    "		</div>\n" +
    "		<div class=\"section\">\n" +
    "			<h5 class=\"heading\">\n" +
    "				Invoicing\n" +
    "			</h5>\n" +
    "			<div class=\"info\">\n" +
    "				You will be invoiced ${{invoice.amount_remaining}} after you {{previouslyLaunched ? 'relaunch' : 'launch'}}.\n" +
    "			</div>\n" +
    "		</div>\n" +
    "		<div>\n" +
    "			<table class=\"invoice\">\n" +
    "				<tr>\n" +
    "					<th>Product</th>\n" +
    "					<th>Rate</th>\n" +
    "					<th>EE</th>\n" +
    "					<th class=\"right\">Total</th>\n" +
    "				</tr>\n" +
    "				<tr ng-repeat=\"item in invoice.items\">\n" +
    "					<td>{{item.displayName}}</td>\n" +
    "					<td>${{item.amount}}</td>\n" +
    "					<td>{{item.quantity}}</td>\n" +
    "					<td class=\"right\">${{item.total}}</td>\n" +
    "				</tr>\n" +
    "				<tr>\n" +
    "					<td>Past charges</td>\n" +
    "					<td></td>\n" +
    "					<td></td>\n" +
    "					<td class=\"right\">-${{invoice.amount_paid}}</td>\n" +
    "				</tr>\n" +
    "				<tr class=\"sum-row\">\n" +
    "					<td>This Invoice</td>\n" +
    "					<td></td>\n" +
    "					<td></td>\n" +
    "					<td class=\"right\">${{invoice.amount_remaining}}</td>\n" +
    "				</tr>\n" +
    "				<tr ng-if=\"invoice.amount_remaining > 0\">\n" +
    "					<td class=\"disclaimer\">\n" +
    "						<i>Plus sales tax, as required by state law<i>\n" +
    "					</td>\n" +
    "				</tr>\n" +
    "			</table>\n" +
    "		</div>\n" +
    "		<div ng-if=\"productToPublishKey === 'benefits_sneak_peek'\" class=\"confirm\">\n" +
    "			Please make sure all information is correct before you launch.</div>\n" +
    "		<div class=\"confirm\">\n" +
    "			Are you ready to {{previouslyLaunched ? 'relaunch' : 'launch'}}?\n" +
    "		</div>\n" +
    "	</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\">Oops, forget it.</button>\n" +
    "		<button class=\"invert\" ng-click=\"launch()\">{{previouslyLaunched ? 'Relaunch' : 'Launch'}}</button>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/lightbox-modal.html",
    "<div class=\"lightbox\">\n" +
    "    <div class=\"image-wrapper\">\n" +
    "      <img src=\"{{imgSrc}}\" />\n" +
    "    </div>\n" +
    "    <button ng-click=\"_ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/loader.html",
    "<div class=\"btf-modal loader\">\n" +
    "    <h4 class=\"title\" ng-bind-html = \"message\"></h4>\n" +
    "    <div class=\"body\">\n" +
    "    	<div class=\"wheel\"></div>\n" +
    "			<div class=\"submessage\">{{submessage}}</div>\n" +
    "    </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/lock-modal.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">{{headerText}}</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<div class=\"field-wrapper\" focus-first=\"true\">\n" +
    "			<p class=\"valid-error-message\" ng-show=\"errorMessage !== ''\">{{errorMessage}}</p>\n" +
    "			<p>{{text}}</p>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\" ng-hide=\"!cancelText\">{{cancelText}}</button>\n" +
    "		<button ng-click=\"ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "	</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/preview-confirm-popup.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">{{headerText}}</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<p>\n" +
    "			You are about to generate a preview link to the ALEX experience you've configured for {{configuration.name}}.\n" +
    "		</p>\n" +
    "\n" +
    "		<h5>Expiration</h5>\n" +
    "		<p>\n" +
    "			Your preview will expire 5 days from today's date. You can preview the experience as many times as you need to until you get it <i>just right</i>.\n" +
    "		</p>\n" +
    "\n" +
    "	</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\" ng-hide=\"!cancelText\">{{cancelText}}</button>\n" +
    "		<button ng-click=\"ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/preview-popup.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">{{headerText}}</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<h5>Check it out</h5>\n" +
    "		<p>\n" +
    "			Your preview is available <a href=\"{{signedUrl}}\" target=\"_blank\">here</a>! This is a temporary, and complex URL. You should save it somewhere or write it on your forehead.\n" +
    "		</p>\n" +
    "		<h5>Details</h5>\n" +
    "		<p>\n" +
    "			But really, don't forget this is <strong>only</strong> a preview experience, and will expire on <strong>{{(expiryDate * 1000) | date}}</strong>. You should use this preview <strong>only</strong> for testing your experience. \n" +
    "		</p>\n" +
    "		<p>\n" +
    "			If you need to make changes to the experience, you can always unlock the configuration, make your changes, and preview it again. \n" +
    "		</p>\n" +
    "		<p>\n" +
    "			If the experience is good-as-gold, then you can go straight to publishing it, and we'll get the real deal started!\n" +
    "		</p>\n" +
    "	</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\" ng-hide=\"!cancelText\">{{cancelText}}</button>\n" +
    "		<button ng-click=\"ok()\" ng-hide=\"!okText\">{{okText}}</button>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/publish-confirm-popup.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 ng-if=\"!videoBuild\" class=\"title\">Are you sure?</h4>\n" +
    "	<span ng-if=\"!videoBuild\">\n" +
    "		<div class=\"body\" style=\"min-height:5em\">\n" +
    "			<span ng-if=\"!consumerToPublish.options.verb\">\n" +
    "				This will publish <strong>{{productToPublish.name}}</strong> to <strong>{{consumerToPublish.name}}</strong>. \n" +
    "			</span>\n" +
    "			<span ng-if=\"consumerToPublish.options.verb\">\n" +
    "				This will {{consumerToPublish.options.verb}} <strong>{{productToPublish.name}}</strong>.\n" +
    "			</span>\n" +
    "		</div>\n" +
    "	</span>\n" +
    "	<div ng-if=\"!videoBuild\" class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\">cancel</button>\n" +
    "		<button ng-click=\"ok()\">I'm sure!</button>\n" +
    "	</div>\n" +
    "	<!-- VIDEO TEMPLATE BELOW -->\n" +
    "	<h4 ng-if=\"videoBuild\" class=\"title\">Launching {{productToPublish.name}}</h4>\n" +
    "	<span ng-if=\"videoBuild\">\n" +
    "		<div class=\"body\" style=\"min-height:5em\">\n" +
    "			<span>\n" +
    "				You are about to launch {{productToPublish.name}} for {{customerName}}. Once you launch, you should receive an email within 30 minutes with your video's URL. If you don't see that email, please get in touch with resellers@jellyvision.com.\n" +
    "			</span>\n" +
    "		</div>\n" +
    "		<div class=\"body\">\n" +
    "			<b>Please make sure all information is correct before you launch.</b>\n" +
    "		</div>\n" +
    "	</span>\n" +
    "	<div ng-if=\"videoBuild\" class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\">Oops, forget it</button>\n" +
    "		<button ng-click=\"ok()\">{{action}}</button>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/publish-popup.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">{{action}} a Product</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<div>\n" +
    "			<label>\n" +
    "				Product: \n" +
    "			</label>\n" +
    "			<select name=\"name\" ng-model=\"productToPublish\" ng-options=\"product as product.name for product in products\">\n" +
    "			</select>\n" +
    "		</div>\n" +
    "		<div ng-show=\"action == 'Publish'\">\n" +
    "			<label>\n" +
    "				Environment\n" +
    "			</label>\n" +
    "			<select name=\"name\" ng-model=\"consumerToPublish\" ng-options=\"consumer as consumer.name for consumer in productToPublish.Consumers\">\n" +
    "			</select>\n" +
    "			<button ng-if=\"consumerToPublish && user.role === 'employee'\" class=\"link\" ng-click=\"preview()\"><span class=\"fa fa-search\"></span> Preview data</button>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "	<div class=\"buttons\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\">cancel</button>\n" +
    "		<button class=\"invert\" ng-click=\"pick()\">{{action}}</button>\n" +
    "	</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/modals/upload-csv-modal.html",
    "<div class=\"btf-modal\">\n" +
    "	<h4 class=\"title\">Upload CSV</h4>\n" +
    "	<div class=\"body\">\n" +
    "		<input type=\"file\" accept=\".csv\" onchange=\"angular.element(this).scope().upload(event)\">\n" +
    "		<button class=\"cancel link\" ng-click=\"cancel()\">Cancel</button>\n" +
    "		<button ng-click=\"ok()\" ng-disabled=\"!file\">Upload</button>\n" +
    "	</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/side-nav/configuration-nav.html",
    "<!--\n" +
    "Menu hierarchy, high-level:\n" +
    "\n" +
    "label in labels\n" +
    "  module in mods\n" +
    "    unit in module.Units (name===settings)\n" +
    "      section in unit.sections\n" +
    "    planType in module.definition.planTypes\n" +
    "      unit in module.Units (name===planType.name) (length > 1)\n" +
    "        section in unit.sections\n" +
    "      unit in module.Units (name===planType.name) (length <= 1)\n" +
    "        section in unit.sections\n" +
    "-->\n" +
    "\n" +
    "<v-accordion class=\"animate secondary-nav side-nav\">\n" +
    "  <v-pane ng-repeat=\"label in labels | filter:shouldShowLabel\" expanded=\"label.isExpanded\" class=\"animate side-nav-group\" ng-hide=\"user.role === 'customer' && label.title === 'ALEX Go'\">\n" +
    "    <v-pane-header class=\"animate\">\n" +
    "      <div\n" +
    "        class=\"side-nav-title\"\n" +
    "        ng-if=\"!label.action\"\n" +
    "        ng-class=\"{'ng-invalid': !label.valid, 'expandable': label.title !== 'Eligibility Questions'}\"\n" +
    "        ng-show=\"(mods = modulesForLabel(label)).length > 0\"\n" +
    "        >\n" +
    "        {{label.title}}\n" +
    "        <span ng-if='configuration.viewOptions.changes.enabled && label.edited' class=\"edited\">edited</span>\n" +
    "        <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[label.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "      </div>\n" +
    "      <div ng-if=\"label.action\" class=\"side-nav-title\" ng-click=\"label.action()\">\n" +
    "        {{label.title}}\n" +
    "      </div>\n" +
    "    </v-pane-header>\n" +
    "    <v-pane-content class=\"animate\">\n" +
    "      <div ng-show=\"(mods = modulesForLabel(label)).length > 0\"><!-- v-accordion cannot be the immediate child of another v-accordion component  ¯\\_(ツ)_/¯ -->\n" +
    "        <v-accordion\n" +
    "          ng-repeat=\"module in mods | filter:{active: true}\" class=\"animate side-nav-sub-group\" ng-hide=\"(module.name ==='chat' && ($root.isDIY(configuration.customerTier) || user.role !== 'employee') || shouldCheck(module.name, configuration.customerTier))\"\n" +
    "          >\n" +
    "          <v-pane class=\"animate\">\n" +
    "            <v-pane-header\n" +
    "              class=\"animate side-nav-sub-title\"\n" +
    "              ng-class=\"{'ng-invalid': !module.appearsValid, 'expandable': module.Units}\">\n" +
    "              {{module.title || module.definition.description}}\n" +
    "              <span ng-if='configuration.viewOptions.changes.enabled && module.edited' class=\"edited\">edited</span>\n" +
    "              <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[module.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "              <span ng-if=\"user.role === 'employee' && module.status !== 'UP_TO_DATE'\" class=\"fa fa-exclamation-triangle\" title=\"Builder is updating this configuration with the latest fields. If you refresh after a few minutes, everything should be all set!\"></span>\n" +
    "            </v-pane-header>\n" +
    "            <v-pane-content class=\"animate\">\n" +
    "              <!-- Settings Units -->\n" +
    "              <v-accordion\n" +
    "                class=\"animate side-nav-sub-nav\"\n" +
    "                ng-repeat=\"unit in module.Units | filter:{name: 'settings'}:true\"\n" +
    "                ng-hide=\"shouldCheck(module.name, configuration.customerTier, unit.name)\">\n" +
    "                <v-pane class=\"animate\">\n" +
    "                  <v-pane-header\n" +
    "                    class=\"side-nav-item\"\n" +
    "                    ui-sref-active=\"activeUnit\"\n" +
    "                    ng-class=\"{'ng-invalid': !module.appearsValid && !unit.data.valid, 'expandable': unit.sections.length > 1}\">\n" +
    "                    <a\n" +
    "                      ui-sref=\"configuration.module.unit({moduleName: module.name, unitName: unit.name, unitId: unit.id})\"\n" +
    "                      title=\"{{unitDisplayName(unit)}}\"\n" +
    "                      ng-hide=\"(module.name.includes('custom_module') && user.role === 'partner')\"\n" +
    "                      >\n" +
    "                      {{unitDisplayName(unit)}}\n" +
    "                    </a>\n" +
    "                    <span ng-if='configuration.viewOptions.changes.enabled && unit.edited' class=\"edited\">edited</span>\n" +
    "                    <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[unit.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                  </v-pane-header>\n" +
    "                  <!-- Sections for Settings Units -->\n" +
    "                  <v-pane-content class=\"side-nav-sub-sub-item\">\n" +
    "                    <ul class=\"side-nav-sections\" ng-if=\"unit.sections.length > 1\">\n" +
    "                      <li\n" +
    "                        class=\"side-nav-section\"\n" +
    "                        ng-class=\"{'ng-invalid': !section.valid}\"\n" +
    "                        ng-repeat=\"section in unit.sections | filter: canSeeSection\"\n" +
    "                        ui-sref-active=\"activeSection\"\n" +
    "                        >\n" +
    "                        <a ui-sref=\"configuration.module.unit.section({\n" +
    "                            moduleName: module.name,\n" +
    "                            unitName: unit.name,\n" +
    "                            unitId: unit.id,\n" +
    "                            sectionName: section.name,\n" +
    "                          })\">{{section.name}}</a>\n" +
    "                          <span class=\"edited\" ng-if='configuration.viewOptions.changes.enabled && section.edited'>edited</span>\n" +
    "                          <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[section.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                      </li>\n" +
    "                    </ul>\n" +
    "                  </v-pane-content>\n" +
    "                </v-pane>\n" +
    "              </v-accordion>\n" +
    "              <!-- Plan Types -->\n" +
    "              <div ng-repeat=\"planType in module.definition.planTypes\">\n" +
    "                <v-accordion\n" +
    "                  class=\"animate side-nav-sub-nav\"\n" +
    "                  ng-if=\"module.definition.planTypes.length > 1\">\n" +
    "                  <v-pane class=\"animate\">\n" +
    "                    <v-pane-header\n" +
    "                      class=\"side-nav-item expandable\"\n" +
    "                      ng-class=\"{'ng-invalid': planTypeUnitsInvalid(planType, module)}\"\n" +
    "                      >\n" +
    "                      <button class=\"plan-type\" type=\"button\">\n" +
    "                        {{planType.title}}\n" +
    "                        <span ng-if='configuration.viewOptions.changes.enabled && planTypeUnitsEdited(planType, module)' class=\"edited\">edited</span>\n" +
    "                        <span ng-class='[planTypeUnitsComments(planType, module) > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                      </button>\n" +
    "                    </v-pane-header>\n" +
    "                    <!-- Plan Type Units (length > 1) -->\n" +
    "                    <v-pane-content>\n" +
    "                      <v-accordion\n" +
    "                      class=\"animate side-nav-sub-nav\"\n" +
    "                      ng-repeat=\"unit in module.Units | filter:{name: planType.name}:true\">\n" +
    "                      <v-pane class=\"animate\">\n" +
    "                        <v-pane-header\n" +
    "                          class=\"side-nav-item\"\n" +
    "                          ui-sref-active=\"activeUnit\"\n" +
    "                          ng-class=\"{'ng-invalid': !unit.data.valid, 'expandable': unit.sections.length > 1}\">\n" +
    "                          <a\n" +
    "                            ui-sref=\"configuration.module.unit({moduleName: module.name, unitName: unit.name, unitId: unit.id})\"\n" +
    "                            title=\"{{unitDisplayName(unit)}}\"\n" +
    "                            >\n" +
    "                            {{unitDisplayName(unit)}}\n" +
    "                          </a>\n" +
    "                          <span ng-if='configuration.viewOptions.changes.enabled && unit.edited' class=\"edited\">edited</span>\n" +
    "                          <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[unit.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                          <span\n" +
    "                            class=\"more\"\n" +
    "                            extra-menu=\"plan\"\n" +
    "                            ng-hide=\"!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier))\"\n" +
    "                            >\n" +
    "                            <span\n" +
    "                              class=\"extra-menu-item\"\n" +
    "                              title=\"duplicate plan\"\n" +
    "                              ng-click=\"duplicateUnitForModule(module, unit)\">\n" +
    "                              <span class=\"fa fa-copy\"></span>\n" +
    "                              <span class=\"menu-item-label\">Duplicate</span>\n" +
    "                            </span>\n" +
    "                            <span\n" +
    "                              class=\"extra-menu-item\"\n" +
    "                              title=\"delete plan\"\n" +
    "                              ng-click=\"removeUnitFromModule(module, unit)\"\n" +
    "                              >\n" +
    "                              <span class=\"fa fa-trash-o\"></span>\n" +
    "                              <span>Delete</span>\n" +
    "                            </span>\n" +
    "                          </span>\n" +
    "                        </v-pane-header>\n" +
    "                        <!-- Sections for Plan Units -->\n" +
    "                        <v-pane-content class=\"side-nav-sub-sub-item\">\n" +
    "                          <!-- Conditional \"Import Plan Details\" Button -->\n" +
    "                          <div\n" +
    "                            class=\"upload-document-container\"\n" +
    "                            ng-if=\"(module.name === 'medical_plan' && planType.name === 'plan') && isFeatureEnabled('builder-27-file-uploader')\">\n" +
    "                            <a\n" +
    "                              class=\"upload-document-link\"\n" +
    "                              ui-sref=\"configuration.module.unit.upload({\n" +
    "                                  moduleName: module.name,\n" +
    "                                  unitName: unit.name,\n" +
    "                                  unitId: unit.id })\"\n" +
    "                            >\n" +
    "                              Import Plan Details\n" +
    "                            </a>\n" +
    "                          </div>\n" +
    "\n" +
    "                          <ul class=\"side-nav-sections\" ng-if=\"unit.sections.length > 1\">\n" +
    "                            <li\n" +
    "                              class=\"side-nav-section\"\n" +
    "                              ng-class=\"{'ng-invalid': !section.valid}\"\n" +
    "                              ng-repeat=\"section in unit.sections | filter: canSeeSection\"\n" +
    "                              ui-sref-active=\"activeSection\"\n" +
    "                            >\n" +
    "                              <a\n" +
    "                                ui-sref=\"configuration.module.unit.section({\n" +
    "                                  moduleName: module.name,\n" +
    "                                  unitName: unit.name,\n" +
    "                                  unitId: unit.id,\n" +
    "                                  sectionName: section.name,\n" +
    "                                })\"\n" +
    "                              >\n" +
    "                                {{section.name}}\n" +
    "                              </a>\n" +
    "                              <span\n" +
    "                                ng-if=\"configuration.viewOptions.changes.enabled && section.edited\"\n" +
    "                                class=\"edited\"\n" +
    "                              >\n" +
    "                                edited\n" +
    "                              </span>\n" +
    "                              <span\n" +
    "                                ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\"\n" +
    "                                ng-class='[section.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'\n" +
    "                              ></span>\n" +
    "                            </li>\n" +
    "                          </ul>\n" +
    "                        </v-pane-content>\n" +
    "\n" +
    "                      </v-pane>\n" +
    "                    </v-accordion>\n" +
    "                    <div\n" +
    "                      class=\"side-nav-item new-unit\"\n" +
    "                      ng-click=\"addUnitToModule(module, planType.name)\"\n" +
    "                      ng-show=\"userCan('edit this configuration', this)\"\n" +
    "                      ng-if=\"module.definition.hasPlans\">\n" +
    "                    <i\n" +
    "                      class=\"fa fa-plus-square\"\n" +
    "                      title=\"add\">\n" +
    "                    </i>\n" +
    "                    <span>\n" +
    "                      add new\n" +
    "                    </span>\n" +
    "                  </div>\n" +
    "                    </v-pane-content>\n" +
    "                  </v-pane>\n" +
    "                </v-accordion>\n" +
    "                <!-- Plan Type Units (length <= 1) -->\n" +
    "                <v-accordion\n" +
    "                  ng-if=\"module.definition.planTypes.length <= 1\"\n" +
    "                  class=\"animate side-nav-sub-nav\"\n" +
    "                  ng-repeat=\"unit in module.Units | filter:{name: planType.name}:true\">\n" +
    "                <v-pane class=\"animate\">\n" +
    "                  <v-pane-header\n" +
    "                    class=\"side-nav-item\"\n" +
    "                    ui-sref-active=\"activeUnit\"\n" +
    "                    ng-class=\"{'ng-invalid': !unit.data.valid, 'expandable': unit.sections.length > 1}\">\n" +
    "                    <a\n" +
    "                      ui-sref=\"configuration.module.unit({moduleName: module.name, unitName: unit.name, unitId: unit.id})\"\n" +
    "                      title=\"{{unitDisplayName(unit)}}\"\n" +
    "                      >\n" +
    "                      {{unitDisplayName(unit)}}\n" +
    "                    </a>\n" +
    "                    <span ng-if='configuration.viewOptions.changes.enabled && unit.edited' class=\"edited\">edited</span>\n" +
    "                    <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[unit.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                    <span\n" +
    "                      class=\"more\"\n" +
    "                      extra-menu=\"plan\"\n" +
    "                      ng-hide=\"!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier))\"\n" +
    "                      >\n" +
    "                      <span\n" +
    "                        class=\"extra-menu-item\"\n" +
    "                        title=\"duplicate plan\"\n" +
    "                        ng-click=\"duplicateUnitForModule(module, unit)\">\n" +
    "                        <span class=\"fa fa-copy\"></span>\n" +
    "                        <span class=\"menu-item-label\">Duplicate</span>\n" +
    "                      </span>\n" +
    "                      <span\n" +
    "                        class=\"extra-menu-item\"\n" +
    "                        title=\"delete plan\"\n" +
    "                        ng-click=\"removeUnitFromModule(module, unit)\"\n" +
    "                        >\n" +
    "                        <span class=\"fa fa-trash-o\"></span>\n" +
    "                        <span>Delete</span>\n" +
    "                      </span>\n" +
    "                    </span>\n" +
    "                  </v-pane-header>\n" +
    "                  <!-- Sections for Plan Units -->\n" +
    "                  <v-pane-content class=\"side-nav-sub-sub-item\">\n" +
    "                    <ul class=\"side-nav-sections\" ng-if=\"unit.sections.length > 1\">\n" +
    "                      <li\n" +
    "                        class=\"side-nav-section\"\n" +
    "                        ng-class=\"{'ng-invalid': !section.valid}\"\n" +
    "                        ng-repeat=\"section in unit.sections | filter: canSeeSection\"\n" +
    "                        ui-sref-active=\"activeSection\"\n" +
    "                        >\n" +
    "                        <a ui-sref=\"configuration.module.unit.section({\n" +
    "                          moduleName: module.name,\n" +
    "                          unitName: unit.name,\n" +
    "                          unitId: unit.id,\n" +
    "                          sectionName: section.name,\n" +
    "                        })\">{{section.name}}</a>\n" +
    "                        <span ng-if='configuration.viewOptions.changes.enabled && section.edited' class=\"edited\">edited</span>\n" +
    "                        <span ng-hide=\"user.role === 'partner' && $root.isDIY(configuration.customerTier)\" ng-class='[section.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "                      </li>\n" +
    "                    </ul>\n" +
    "                  </v-pane-content>\n" +
    "                </v-pane>\n" +
    "              </v-accordion>\n" +
    "              <div\n" +
    "                class=\"side-nav-item new-unit\"\n" +
    "                ng-click=\"addUnitToModule(module, planType.name)\"\n" +
    "                ng-show=\"userCan('edit this configuration', this)\"\n" +
    "                ng-if=\"module.definition.hasPlans && module.definition.planTypes.length <=1\">\n" +
    "              <i\n" +
    "                class=\"fa fa-plus-square\"\n" +
    "                title=\"add\">\n" +
    "              </i>\n" +
    "              <span>\n" +
    "                add new\n" +
    "              </span>\n" +
    "            </div>\n" +
    "              </div>\n" +
    "            </v-pane-content>\n" +
    "          </v-pane>\n" +
    "        </v-accordion>\n" +
    "      </div>\n" +
    "    </v-pane-content>\n" +
    "  </v-pane>\n" +
    "</v-accordion>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/side-nav/primary-nav.html",
    "<ul ng-controller=\"ExportHtmlCtrl\" class=\"primary-nav side-nav\">\n" +
    "  <li>\n" +
    "    <div>\n" +
    "      <h1 title=\"{{customer.name}}\">{{customer.name}}</h1>\n" +
    "      <ng-form name=\"configNameForm\" class=\"edit-configuration\" isolate-form>\n" +
    "        <input class=\"name-input\" ng-if=\"!configuration.name && !configuration.editing\" placeholder=\"configuration name\"\n" +
    "          ng-click=\"configuration.editing = !configuration.editing\"></input>\n" +
    "        <input class=\"name-input\" ng-if=\"configuration.editing\" ng-model=\"configuration.name\"\n" +
    "          placeholder=\"configuration name\"></input>\n" +
    "        <h2 ng-if=\"!configuration.editing\" title=\"{{configuration.name}}\">{{configuration.name}}</h2>\n" +
    "        <button ng-if=\"configuration.editing && user.role !== 'customer'\" class=\"configuration-button\"\n" +
    "          ng-click=\"saveEdits(configuration)\">Save</button>\n" +
    "        <span ng-if=\"!configuration.editing && user.role !== 'customer'\" class=\"fa fa-pencil\"\n" +
    "          ng-click=\"configuration.editing = !configuration.editing\"></span>\n" +
    "      </ng-form>\n" +
    "      <div class=\"customer-tier\" ng-if=\"user.role === 'employee'\">\n" +
    "        <label>ALEX Package</label>\n" +
    "        <select ng-model=\"configuration.customerTier\" ng-change=\"updateConfigurationTier(configuration)\">\n" +
    "          <option ng-repeat=\"(key, value) in availableTiers\" value=\"{{value}}\">\n" +
    "            {{value}}\n" +
    "          </option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "      <div>\n" +
    "        <button ng-if=\"userCan('edit stuff')\"\n" +
    "          ng-show=\"configuration.locked && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\"\n" +
    "          class=\"lock-button action\" ng-click=\"unlockConfiguration()\">\n" +
    "          <span class=\"fa fa-unlock\"></span>\n" +
    "          Unlock\n" +
    "        </button>\n" +
    "        <button ng-if=\"userCan('edit stuff')\"\n" +
    "          ng-show=\"!configuration.locked && (user.role === 'employee' || $root.isDIY(configuration.customerTier))\"\n" +
    "          class=\"lock-button action\" ng-click=\"confirmLockConfiguration($event, configuration)\">\n" +
    "          <span class=\"fa fa-lock\"></span>\n" +
    "          Lock\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li ui-sref-active=\"activePage\" ng-if=\"user.role === 'employee' || $root.isDIY(configuration.customerTier)\">\n" +
    "    <div>\n" +
    "      <a ui-sref=\"configuration.setup\" title=\"Product Setup\">\n" +
    "        Enable Products / Modules\n" +
    "      </a>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li ui-sref-active=\"activePage\" ng-if=\"user.role === 'employee' || $root.isDIY(configuration.customerTier)\">\n" +
    "    <div>\n" +
    "      <a ui-sref=\"configuration.publishing\" title=\"URLs / Publish\">\n" +
    "        URLs / Publish\n" +
    "      </a>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li>\n" +
    "    <div>\n" +
    "      <button class=\"export-html-btn\" ng-click=\"doExport()\">\n" +
    "        HTML Export <i class=\"fa fa-external-link\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li ng-if=\"validationEnabled\">\n" +
    "    <div>\n" +
    "      <button class=\"validate-eligibility-btn\" ng-click=\"doValidate()\">\n" +
    "        Check Eligibility <i class=\"fa fa-external-link\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li>\n" +
    "    <ng-include ng-controller=\"ViewOptionsCtrl\" src=\"'views/configuration/side-nav/view-options.html'\"></ng-include>\n" +
    "  </li>\n" +
    "  <li>\n" +
    "    <ng-include ng-controller=\"SharingOptionsCtrl\" src=\"'views/configuration/side-nav/sharing-options.html'\">\n" +
    "    </ng-include>\n" +
    "\n" +
    "    <!-- Should be hidden. -->\n" +
    "    <!-- In the bottom <li> _just_ in case I appear in the DOM. -->\n" +
    "    <div class=\"export-html-wrapper\"></div>\n" +
    "  </li>\n" +
    "</ul>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/side-nav/sharing-options.html",
    "<v-accordion class=\"animate sharing-options\" ng-if=\"user.role === 'employee' && !$root.isDIY(configuration.customerTier)\">\n" +
    "  <!-- leaving this a accordion, anticipating that more content will be here eventually -->\n" +
    "  <v-pane\n" +
    "  	class=\"animate\" expanded=\"sharingOptions.expanded\">\n" +
    "    <v-pane-header class=\"animate\">\n" +
    "      <!--<div>\n" +
    "      	<span ng-if=\"!sharingOptions.expanded\" class=\"fa fa-caret-right\"></span>\n" +
    "      	<span ng-if=\"sharingOptions.expanded\" class=\"fa fa-caret-down\"></span>\n" +
    "      	Sharing Options\n" +
    "      </div>-->\n" +
    "\n" +
    "      <div class=\"side-nav-options-field\">\n" +
    "        <input\n" +
    "          id=\"allowExternalUsers\"\n" +
    "          type=\"checkbox\"\n" +
    "          class=\"checkbox switch\"\n" +
    "          ng-model=\"configuration.allowExternalUsers\"\n" +
    "          ng-change=\"applyChanges()\"\n" +
    "          name=\"allowExternalUsers\"\n" +
    "          ng-click=\"$event.stopPropagation();\">\n" +
    "        <label\n" +
    "        	class=\"switch\"\n" +
    "        	for=\"allowExternalUsers\"\n" +
    "        	ng-click=\"$event.stopPropagation()\"></label>\n" +
    "		  	External Sharing\n" +
    "      </div>\n" +
    "    </v-pane-header>\n" +
    "    <v-pane-content class=\"animate side-nav-options-form\" ng-form=\"sharingOptionsForm\" ng-hide=\"true\"></v-pane-content>\n" +
    "  </v-pane>\n" +
    "</v-accordion>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/configuration/side-nav/view-options.html",
    "<v-accordion ng-if=\"user.role !== 'partner' || !$root.isDIY(configuration.customerTier)\" class=\"animate side-nav-options\">\n" +
    "  <v-pane\n" +
    "  	class=\"animate\" expanded=\"viewOptions.expanded\">\n" +
    "    <v-pane-header class=\"animate\">\n" +
    "      <div>\n" +
    "      	<span ng-if=\"!viewOptions.expanded\" class=\"fa fa-caret-right\"></span>\n" +
    "      	<span ng-if=\"viewOptions.expanded\" class=\"fa fa-caret-down\"></span>\n" +
    "      	Viewing Options\n" +
    "      </div>\n" +
    "    </v-pane-header>\n" +
    "    <v-pane-content class=\"side-nav-options-form no-border\" ng-form=\"viewOptionsForm\">\n" +
    "      <div class=\"side-nav-options-field\">\n" +
    "        <input\n" +
    "          id=\"reviewEdits\"\n" +
    "          type=\"checkbox\"\n" +
    "          class=\"checkbox switch\"\n" +
    "          ng-model=\"viewOptions.changes.enabled\"\n" +
    "          checked=\"viewOptions.changes.enabled\"\n" +
    "          ng-change=\"applyChanges()\"\n" +
    "          name=\"reviewEdits\"\n" +
    "          ng-click=\"$event.stopPropagation();\">\n" +
    "        <label\n" +
    "        	class=\"switch\"\n" +
    "        	for=\"reviewEdits\"\n" +
    "        	ng-click=\"$event.stopPropagation()\"></label>\n" +
    "		  Review Edits\n" +
    "      </div>\n" +
    "      <div ng-if=\"viewOptions.changes.enabled\">\n" +
    "      	<div class=\"side-nav-options-field\">\n" +
    "	      	<ul>\n" +
    "	      		<li>\n" +
    "	      			<input\n" +
    "	      				id=\"reviewEditsTillToday\"\n" +
    "	      				type=\"radio\"\n" +
    "	      				name=\"changesToShow\"\n" +
    "	      				ng-model=\"viewOptions.changes.type\"\n" +
    "	      				value=\"tillToday\"/>\n" +
    "	      			<label for=\"reviewEditsTillToday\">Track from date</label>\n" +
    "	      		</li>\n" +
    "	      		<li>\n" +
    "	      			<input\n" +
    "	      				id=\"reviewEditsBetweenDates\"\n" +
    "	      				type=\"radio\"\n" +
    "	      				name=\"changesToShow\"\n" +
    "	      				ng-model=\"viewOptions.changes.type\"\n" +
    "	      				value=\"betweenDates\"/>\n" +
    "	      			<label for=\"reviewEditsBetweenDates\">Between two dates</label>\n" +
    "	      		</li>\n" +
    "	      		<li>\n" +
    "	      			<input\n" +
    "	      				type=\"radio\"\n" +
    "	      				name=\"changesToShow\"\n" +
    "	      				id=\"reviewEditsDataRevs\"\n" +
    "	      				ng-model=\"viewOptions.changes.type\"\n" +
    "	      				value=\"betweenDataRevs\"/>\n" +
    "	      			<label for=\"reviewEditsDataRevs\">Between publishes</label>\n" +
    "	      		</li>\n" +
    "	      	</ul>\n" +
    "	      </div>\n" +
    "	      <div class=\"side-nav-options-field\">\n" +
    "		      <div ng-show=\"viewOptions.changes.type !== 'betweenDataRevs'\">\n" +
    "		      	<span\n" +
    "		      		class=\"field-date\"\n" +
    "		      		date\n" +
    "		      		ng-model=\"viewOptions.changes.startDate\"\n" +
    "		      		name=\"field\"\n" +
    "		      		title=\"{{viewOptions.changes.endDate | date : 'medium'}}\"></span>\n" +
    "		      	<span\n" +
    "		      		class=\"field-date\"\n" +
    "		      		date\n" +
    "		      		ng-model=\"viewOptions.changes.endDate\"\n" +
    "		      		name=\"field\"\n" +
    "		      		ng-show=\"viewOptions.changes.type === 'betweenDates'\"\n" +
    "		      		title=\"{{viewOptions.changes.endDate | date : 'medium'}}\"></span>\n" +
    "		      	<span ng-show=\"viewOptions.changes.type === 'tillToday'\"> to current</span>\n" +
    "		      </div>\n" +
    "		      <div ng-show=\"viewOptions.changes.type === 'betweenDataRevs'\">\n" +
    "		      	<select ng-model=\"viewOptions.changes.dataRevStartNumber\" ng-change=\"selectDataRev($event, 'startDate', viewOptions.changes.dataRevStartNumber)\" name=\"dataRevStartNumber\">\n" +
    "		      		<option disabled value=\"\"> - </option>\n" +
    "	            <option ng-repeat=\"dataRev in configuration.dataRevisions\" value=\"{{dataRev.number}}\" title=\"{{dataRev.date | date : 'medium'}}\">{{dataRev.number}} ({{dataRev.date | date : 'EEE, MMM d'}})</option>\n" +
    "	          </select>\n" +
    "	          to\n" +
    "	          <select ng-model=\"viewOptions.changes.dataRevEndNumber\" ng-change=\"selectDataRev($event, 'endDate', viewOptions.changes.dataRevEndNumber)\" name=\"dataRevEndNumber\">\n" +
    "		      		<option disabled value=\"\"> - </option>\n" +
    "	            <option ng-repeat=\"dataRev in configuration.dataRevisions\" value=\"{{dataRev.number}}\" title=\"{{dataRev.date | date : 'medium'}}\">{{dataRev.number}} ({{dataRev.date | date : 'EEE, MMM d'}})</option>\n" +
    "	          </select>\n" +
    "		      </div>\n" +
    "	      </div>\n" +
    "	      <div class=\"view-options-field\">\n" +
    "	      	<button class=\"action\" ng-click=\"applyChanges()\">View Results</button>\n" +
    "	      </div>\n" +
    "	     </div>\n" +
    "    </v-pane-content>\n" +
    "  </v-pane>\n" +
    "</v-accordion>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/answer-choices.html",
    "<ul class=\"choices\" ng-form name=\"choicesForm\" \n" +
    "ng-hide=\"!applying && (question.key === 'state' || question.key === 'state_work')\">\n" +
    "  <li class=\"choice\" ng-repeat=\"choice in question.Choices | orderBy:'id'\">\n" +
    "    <div>\n" +
    "      <span ng-if=\"!applying\">\n" +
    "        <span\n" +
    "          text\n" +
    "          class=\"choice-value min-width-10\"\n" +
    "          ng-model=\"choice.value\"\n" +
    "          required=\"required\"\n" +
    "          placeholder=\"answer text\"\n" +
    "          ng-disabled=\"!userCan('edit eligibility questions', this)\"\n" +
    "          maxlength=\"255\">\n" +
    "        </span>\n" +
    "      </span>\n" +
    "      <span ng-if=\"applying\" class=\"applying\">\n" +
    "        <input\n" +
    "          type=\"checkbox\"\n" +
    "          id=\"{{'choice' + choice.id}}\"\n" +
    "          class=\"checkbox choice-value\"\n" +
    "          ng-checked=\"answerIsSelected(choice, question)\"\n" +
    "          ng-click=\"applyChoice($event, choice, question)\"\n" +
    "          ng-disabled=\"!userCan('edit eligibility questions', this)\"/>\n" +
    "          <label\n" +
    "            class=\"eligibility-choice-label\"\n" +
    "            for=\"{{'choice' + choice.id}}\"\n" +
    "            >\n" +
    "            {{choice.value}}\n" +
    "          </label>\n" +
    "      </span>\n" +
    "<!--       <span class=\"comment-control\" commentable commentable-options=\"{table_name: 'rule_answer_choice', field_name: 'rule_answer_text', model_expr: 'choice', field_type: 'text', custom: true}\"></span> -->\n" +
    "      <span ng-if=\"!applying\">\n" +
    "        <i\n" +
    "          type=\"button\"\n" +
    "          class=\"delete-icon fa fa-trash\"\n" +
    "          title=\"delete\"\n" +
    "          ng-if=\"question.Choices.length > 2\"\n" +
    "          ng-click=\"removeChoice(choice)\">\n" +
    "        </i>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "  <li ng-if=\"!applying\">\n" +
    "    <button\n" +
    "      class=\"invert add-choice\"\n" +
    "      type=\"button\"\n" +
    "      ng-click=\"createChoice()\"\n" +
    "      ng-show=\"userCan('edit eligibility questions', this)\">\n" +
    "      + add choice\n" +
    "    </button>\n" +
    "  </li>\n" +
    "</ul>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/answer-integrations.html",
    "<ul class=\"choices\" ng-form name=\"integrationCode\"\n" +
    "ng-if=\"question.key !== 'state' && question.key !== 'state_work'\">\n" +
    "  <li class=\"choice\" ng-repeat=\"choice in question.Choices\">\n" +
    "    <div>\n" +
    "      <h6>{{choice.value}}</h6>\n" +
    "      <span>\n" +
    "        <span\n" +
    "          text\n" +
    "          class=\"choice-integrationCode min-width-10\"\n" +
    "          ng-model=\"choice.integrationCode\"\n" +
    "          ng-required=\"false\"\n" +
    "          placeholder=\"comma-separated codes\"\n" +
    "          ng-disabled=\"!userCan('edit this configuration', this)\">\n" +
    "        </span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "</ul>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/answer-range.html",
    "<span ng-form=\"form\" class=\"range\">\n" +
    "  <span ng-if=\"applying\" class=\"applying\">\n" +
    "    <span \n" +
    "      dropdown \n" +
    "      ng-model=\"range.operator\" \n" +
    "      ng-required=\"true\" \n" +
    "      ng-disabled=\"!userCan('edit eligibility questions', this)\" \n" +
    "      options=\"operators\">\n" +
    "    </span>\n" +
    "    \n" +
    "    <span>\n" +
    "      <span \n" +
    "        ng-if=\"range.operator !== 'less than'\"\n" +
    "        text \n" +
    "        number\n" +
    "        ng-required=\"true\"\n" +
    "        name=\"min_value\"\n" +
    "        ng-model=\"range.min_value\" \n" +
    "        n-decimals=\"2\"\n" +
    "        placeholder=\"XX\" \n" +
    "        min=\"0\"\n" +
    "        ng-disabled=\"!userCan('edit eligibility questions', this)\" \n" +
    "        class=\"min-width-2\">\n" +
    "      </span>\n" +
    "      <span ng-if=\"range.operator === 'between'\">and</span>\n" +
    "      <span\n" +
    "        ng-if=\"range.operator === 'less than' || range.operator === 'between'\"\n" +
    "        text\n" +
    "        number\n" +
    "        ng-required=\"true\"\n" +
    "        name=\"max_value\"\n" +
    "        ng-model=\"range.max_value\"\n" +
    "        n-decimals=\"2\"\n" +
    "        placeholder=\"XX\"\n" +
    "        min=\"0\"\n" +
    "        ng-disabled=\"!userCan('edit eligibility questions', this)\"\n" +
    "        class=\"min-width-2\">\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\" ng-messages=\"form.min_value.$error || form.max_value.$error\">\n" +
    "        <div ng-message=\"n-decimals\">Please enter a maximum of 2 decimal places.</div>\n" +
    "      </div>\n" +
    "      <span ng-if=\"range.operator === 'between'\">\n" +
    "        <div class=\"error-messages\" ng-messages=\"form.max_value.$error\">\n" +
    "          <div ng-message=\"required\">Please enter a minimum and maximum amount.</div>\n" +
    "        </div>\n" +
    "      </span>\n" +
    "    </span>\n" +
    "  </span>\n" +
    "</span>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/answer-zip.html",
    "<div ng-form=\"form\" class=\"zip\" novalidate>\n" +
    "  Employee\n" +
    "  <span\n" +
    "    toggle\n" +
    "    ng-model=\"zipQuestionInstance.inclusive\"\n" +
    "    on-label=\"lives\"\n" +
    "    off-label=\"does not live\"\n" +
    "    ng-disabled=\"!userCan('edit eligibility questions', this)\"\n" +
    "  ></span>\n" +
    "  in one of the zip codes listed in\n" +
    "  <div\n" +
    "    class=\"file-input\"\n" +
    "    file ng-model=\"zipQuestionInstance\" is-zip=\"true\"\n" +
    "    ng-disabled=\"!userCan('edit eligibility questions', this)\"\n" +
    "    filetype=\".csv\"\n" +
    "  ></div>\n" +
    "  <a class=\"download-link\"></a>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/question.html",
    "<div ng-form>\n" +
    "  <div class=\"question-row\" ng-form=\"title\">\n" +
    "    <label>Question</label>\n" +
    "    <span ng-if=\"!applying && !question.key\">\n" +
    "      <span\n" +
    "        text\n" +
    "        class=\"question-title min-width-20\"\n" +
    "        ng-model=\"question.text\"\n" +
    "        placeholder=\"Question Title\"\n" +
    "        ng-disabled=\"!userCan('edit this configuration', this)\"\n" +
    "        maxlength=\"255\">\n" +
    "      </span>\n" +
    "      <span class=\"in-use\" ng-if=\"question.in_use\">In use</span>\n" +
    "      <div class=\"error-messages\"></div>\n" +
    "    </span>\n" +
    "    <span ng-if=\"applying || question.key\" title=\"{{question.key}}\">\n" +
    "      <span class=\"question-title\">{{question.text}}</span>\n" +
    "    </span>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"question-row\" ng-form=\"clarification\">\n" +
    "    <label>Clarification</label>\n" +
    "    <span ng-if=\"!applying\">\n" +
    "      <span\n" +
    "        text\n" +
    "        class=\"clarification min-width-20\"\n" +
    "        ng-model=\"question.clarification\"\n" +
    "        ng-required=\"false\"\n" +
    "        placeholder=\"optional clarification\"\n" +
    "        ng-disabled=\"!userCan('edit this configuration', this)\"\n" +
    "        maxlength=\"350\">\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\"></div>\n" +
    "    </span>\n" +
    "    <span ng-if=\"applying\">\n" +
    "      <span class=\"clarification\">{{question.clarification}}</span>\n" +
    "    </span>\n" +
    "\n" +
    "    <div class=\"question-row\" ng-form=\"questionCodeForm\" ng-if=\"isIntegrated && !isBroker && !applying\">\n" +
    "    <label>Question Code</label>\n" +
    "    <span>\n" +
    "      <span\n" +
    "        text\n" +
    "        class=\"question-code\"\n" +
    "        ng-model=\"question.questionCode\"\n" +
    "        ng-required=\"false\"\n" +
    "        ng-disabled=\"!userCan('edit this configuration', this)\">\n" +
    "      </span>\n" +
    "      <div class=\"error-messages\"></div>\n" +
    "    </span>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"question-row\" ng-if=\"question.answerType == 'choice'\">\n" +
    "    <label ng-if=\"!applying && (question.key !== 'state' && question.key !== 'state_work')\">\n" +
    "    Choices</label>\n" +
    "    <answer-choices class=\"answers\"></answer-choices>\n" +
    "  </div>\n" +
    "  <div class=\"question-row\" ng-if=\"question.answerType == 'range'\">\n" +
    "    <label>Range</label>\n" +
    "    <answer-range class=\"answers\" ng-if=\"applying\"></answer-range>\n" +
    "  </div>\n" +
    "  <div class=\"question-row\" ng-if=\"question.answerType == 'zip'\">\n" +
    "    <label>Zip Codes</label>\n" +
    "    <answer-zip class=\"answers\" ng-if=\"applying\"></answer-zip>\n" +
    "  </div>\n" +
    "  <div class=\"question-row\" ng-if=\"isIntegrated && !isBroker && !applying && question.answerType == 'choice'\">\n" +
    "    <label\n" +
    "    ng-if=\"question.key !== 'state' && question.key !== 'state_work'\">\n" +
    "    Integration</label>\n" +
    "    <answer-integrations class=\"answers\"></answer-integrations>\n" +
    "  </div>\n" +
    "  <div class=\"question-row\" ng-if=\"!applying && !isBroker && question.answerType == 'choice'\">\n" +
    "    <span>ALEX</span>\n" +
    "    <span\n" +
    "      toggle\n" +
    "      ng-model=\"question.hideChoices\"\n" +
    "      on-label=\"should\"\n" +
    "      off-label=\"should not\"\n" +
    "    ></span>\n" +
    "    <span>hide choices from users.</span>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/eligibility/questions.html",
    "<div class=\"eligibility-questions\">\n" +
    "    <p>\n" +
    "       Add questions and select answers that would make someone eligible. If you list multiple questions, the employee must give an eligible answer for ALL the questions you list.\n" +
    "    </p>\n" +
    "    <div\n" +
    "        class=\"question\"\n" +
    "        scroll-item\n" +
    "        ng-repeat=\"question in configuration.Questions | filter:questionIsAssociatedToPlan\">\n" +
    "        <div question-history=\"question.id\" ng-show=\"question.applying && questionHistory.changes.length\"></div>\n" +
    "        <div\n" +
    "            question=\"question\"\n" +
    "            associated-questions=\"associatedQuestions\"\n" +
    "            configuration=\"configuration\"\n" +
    "            ng-if=\"!question.applying\"></div>\n" +
    "        <div\n" +
    "            applied-question=\"question\"\n" +
    "            ng-model=\"question\"\n" +
    "            associated-questions=\"associatedQuestions\"\n" +
    "            configuration=\"configuration\"\n" +
    "            ng-if=\"question.applying\"></div>\n" +
    "        <div class=\"question-buttons\" ng-hide=\"!userCan('edit eligibility questions', this)\">\n" +
    "            <button class=\"invert\" ng-click=\"question.applying = false\" ng-if=\"question.applying\"><i class=\"fa fa-pencil\"></i></button>\n" +
    "            <button class=\"invert\" ng-click=\"question.applying = true\" ng-if=\"!question.applying\"><i class=\"fa fa-check\"></i></button>\n" +
    "            <button class=\"invert\" ng-click=\"removeQuestion(question)\"><i class=\"fa fa-close\"></i></button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"eligibility-question-controls\">\n" +
    "        <button\n" +
    "            class=\"invert\"\n" +
    "            ng-hide=\"adding || !userCan('edit eligibility questions', this)\"\n" +
    "            ng-click=\"adding = true\">\n" +
    "                <i class=\"fa fa-plus\"></i> add an eligibility question\n" +
    "        </button>\n" +
    "        <div ng-show=\"adding\">\n" +
    "            <select ng-model=\"selectedQuestionId\" ng-change=\"addQuestion()\">\n" +
    "                <option disabled value=\"\">choose a question</option>\n" +
    "                <option ng-repeat=\"question in possibleQuestions\" value=\"{{question.id}}\">{{question.text}}</option>\n" +
    "                <option disabled value=\"\"></option>\n" +
    "                <option value=\"Add New\">+ New eligibility question</option>\n" +
    "            </select>\n" +
    "            <button class=\"cancel link\" ng-click=\"adding = false\">cancel</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/error-messages/error-messages.html",
    "<div class=\"error-messages\" ng-messages=\"formError\">\n" +
    "  <div class=\"error-message error-message-required\" ng-message=\"required\">This field is required.</div>\n" +
    "  <div ng-repeat=\"(key, message) in getErrorMessages(formError)\">\n" +
    "    <div class=\"error-message\" ng-message-exp=\"key\">{{message}}</div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/character-count.html",
    "<div \n" +
    "	class=\"charCounter\" \n" +
    "	ng-class=\"{'error-message': usedCharacters > maxCharacters}\"\n" +
    "	>\n" +
    "	<span>\n" +
    "		Characters left: {{maxCharacters - usedCharacters}}\n" +
    "	</span>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/collection.html",
    "<div class=\"collection-wrapper field-wrapper\">\n" +
    "  <div class=\"collection\">\n" +
    "    <span class=\"introduction-text\" ng-if=\"displayText\">{{displayText}}</span>\n" +
    "    <div class=\"collection-list\">\n" +
    "      <div class=\"collection-more\">\n" +
    "        <span class=\"introduction-text\">{{collectionName.plural}}</span>\n" +
    "        <span\n" +
    "          extra-menu=\"plan\"\n" +
    "          ng-if=\"userCan('upload collection csvs', this) && definition.name === 'premium_groups'\"\n" +
    "        >\n" +
    "          <span\n" +
    "            class=\"extra-menu-item\"\n" +
    "            ng-click=\"openUploadCSVModal()\">\n" +
    "            <span class=\"fa fa-upload\"></span>\n" +
    "            <span class=\"menu-item-label\">Upload CSV</span>\n" +
    "          </span>\n" +
    "          <span\n" +
    "            class=\"extra-menu-item\"\n" +
    "            ng-click=\"downloadCollectionCSV()\"\n" +
    "          >\n" +
    "            <span class=\"fa fa-download\"></span>\n" +
    "            <span>Download CSV</span>\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <ul\n" +
    "        module=\"module\"\n" +
    "        >\n" +
    "        <li\n" +
    "          ng-repeat=\"item in items\"\n" +
    "          title=\"{{item.name.value}}\"\n" +
    "          ng-class=\"{active: activeItem === item}\"\n" +
    "          ng-click=\"selectItem(item)\"\n" +
    "          item-id=\"{{item.id}}\">\n" +
    "          <div class=\"collection-item\" ng-class=\"{'ng-invalid': !item.valid}\"><!-- IE fix - need flex on the child, not the <li/> -->\n" +
    "            <span ng-hide=\"user.role === 'partner'\" ng-class='[item.commentCount > 0 ? \"fa fa-comment\" : \"\" ]'></span>\n" +
    "            <div history=\"item.history\"></div>\n" +
    "            <div class=\"collection-name\">\n" +
    "              <div ng-show=\"!editing\">\n" +
    "                {{item.name.value}}\n" +
    "              </div>\n" +
    "              <div ng-show=\"editing\">\n" +
    "                <text\n" +
    "                  ng-model=\"item.name.value\"\n" +
    "                  placeholder=\"Item Name\"\n" +
    "                  ></text>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"collection-buttons\" ng-hide=\"locked\">\n" +
    "              <span class=\"edit-icon fa fa-pencil\" ng-show=\"!editing\" ng-click=\"editing = true\"></span>\n" +
    "              <span class=\"edit-icon fa fa-check\" ng-show=\"editing\" ng-click=\"editing = false\"></span>\n" +
    "              <span class=\"delete-icon fa fa-trash\" ng-if=\"collectionLength > minItems && !(!userCan('edit this configuration', this) || (user.role !== 'employee' && !$root.isDIY(configuration.customerTier)))\" ng-click=\"deleteItem($event, item)\"></span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "      <div class=\"add-collection-item\">\n" +
    "        <button class=\"invert\" ng-click=\"addItem()\" ng-hide=\"(maxItems && collectionLength >= maxItems) || !userCan('edit this configuration', this)\">\n" +
    "          <span class=\"fa fa-plus\"></span>\n" +
    "          add a new {{collectionName.singular}}\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"collection-fields\" ng-show=\"activeItem\" ng-form=\"activeItemForm\"></div>\n" +
    "    <div class=\"clearfix\"></div>\n" +
    "  </div>\n" +
    "  <div class=\"error-messages\" ng-messages=\"$parent[definition.name].$error\">\n" +
    "    <div class=\"error-message\" ng-message=\"collectionHasMinItems\">This collection needs more items.</div>\n" +
    "    <div class=\"error-message\" ng-message=\"collectionHasMaxItems\">This collection has too many items.</div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/date.html",
    "<span class=\"field-date focusable\" track-visits tabindex=\"0\" ng-class=\"{disabled:disabled}\"\n" +
    "	><span class=\"field-date-display\"></span\n" +
    "	><input type=\"text\" style=\"display:none;\"></span\n" +
    ">");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/dropdown.html",
    "<select class=\"field-dropdown focusable\" ng-options=\"option.value as option.text for option in _options\" tabindex=\"0\"></select>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/eligibility.html",
    "<div class=\"eligibility\">\n" +
    "	<div ng-if=\"field.section !== 'Procedures' && !showHealthCareDropdownWarning\">\n" +
    "	  <h5>Eligibility</h5>\n" +
    "	  \n" +
    "	  <div available-to-all ng-form=\"available_to_all\">\n" +
    "		This {{name}} <span toggle ng-model=\"eligibilityData.available_to_all\" on-label=\"is\" off-label=\"is not\" ng-disabled=\"!userCan('edit this configuration', this)\"></span> available to all employees.\n" +
    "		<p class=\"error-message\" ng-show=\"available_to_all.$error.eligibility\">\n" +
    "		  If the plan is not available to all employees, you must add eligibility questions.\n" +
    "		</p>\n" +
    "	  </div>\n" +
    "	  <div history=\"availableToAllHistory\">\n" +
    "		This {{name}} <span toggle ng-model=\"change.change\" on-label=\"is\" off-label=\"is not\" ng-disabled=\"true\"></span> available to all employees.\n" +
    "	  </div>\n" +
    "	  \n" +
    "	  <div class=\"field-wrapper\" ng-if=\"showPlanEligibilityWarning\">\n" +
    "		<div class=\"alert alert-warning\">\n" +
    "		        Users can only be eligible for one plan in this module.\n" +
    "		</div>\n" +
    "	  </div>  \n" +
    "	  <div keep-scroll eligibility-questions ng-if=\"!eligibilityData.available_to_all\"></div>\n" +
    "	</div>\n" +
    "	<div class=\"field-wrapper\" ng-if=\"showMedicalDropdownWarning\">\n" +
    "	  <div class=\"alert alert-warning\">\n" +
    "		The \"Copay with range\" payment type can only be used for plans administered by Surest, Coupe, or SimplePay.\n" +
    "	  </div>\n" +
    "	</div>\n" +
    "	<div class=\"field-wrapper\" ng-if=\"showHealthCareDropdownWarning\">\n" +
    "		<div class=\"alert alert-warning\">\n" +
    "		  If this customer has Teladoc and has purchased the Advanced package, then turn on the Year-Round Support Content for Teladoc in Settings > General.\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/file.html",
    "<span class=\"field-file\">\n" +
    "  <label class=\"field-file-input-label\" >\n" +
    "    {{label}} <span ng-show=\"!isSet\" class=\"fa fa-upload\"></span>\n" +
    "  </label>\n" +
    "  <input type=\"file\" class=\"field-file-input focusable\" accept=\"{{accept}}\">\n" +
    "  <a\n" +
    "    class=\"fa fa-download\"\n" +
    "    ng-show=\"isSet\"\n" +
    "    ng-click=\"onDownload()\"\n" +
    "    title=\"download\"\n" +
    "    ng-href=\"{{downloadLink}}\"\n" +
    "    target=\"_blank\"></a>\n" +
    "  <span class=\"fa fa-trash\" ng-show=\"isSet\" ng-click=\"onDelete()\" title=\"delete\"></span>\n" +
    "</span>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/label.html",
    "<span\n" +
    "><h5 ng-if=\"title_text\" ng-bind-html=\"title_text\"></h5\n" +
    "	><h6 ng-if=\"subtitle_text\" ng-bind-html=\"subtitle_text\"></h6\n" +
    "	><span ng-if=\"body_text\" ng-bind-html=\"body_text\"></span></span\n" +
    ">\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/picker.html",
    "<span>\n" +
    "	<div\n" +
    "	  class=\"field-picker\"\n" +
    "	  ng-dropdown-multiselect=\"\" \n" +
    "	  ng-class=\"{multiselect : multiselect, singleselect : singleselect}\"\n" +
    "	  options=\"collectionItems\" \n" +
    "	  disabled=\"disabled\"\n" +
    "	  selected-model=\"selectedItems\" \n" +
    "	  extra-settings=\"searchSelectAllSettings\"\n" +
    "	  translation-texts=\"customText\"\n" +
    "	  events=\"events\"\n" +
    "	  ng-click=\"updateZIndex()\">\n" +
    "	</div>\n" +
    "	<ul>\n" +
    "		<li ng-repeat=\"item in selectedItemLabels\">\n" +
    "			<a ng-if=\"item.link\" href=\"{{item.link}}\">{{item.label}}</a>\n" +
    "			<span ng-if=\"!item.link\">{{item.label}}</span>\n" +
    "		</li>\n" +
    "	</ul>\n" +
    "</span>\n" +
    "<div class=\"error-messages\" ng-messages=\"$parent[definition.name].$error\">\n" +
    "	<div class=\"error-message\" ng-message=\"pickerHasMinItems\">At least one option must be selected.</div>\n" +
    "</div>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/text.html",
    "<span contenteditable class=\"field-text focusable\" track-visits plain-text-paste tabindex=\"0\"></span>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/fields/toggle.html",
    "<span class=\"field-toggle focusable\" data-tooltip=\"{{titleText}}\" tabindex=\"0\"></span>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/hello/face.html",
    "<div class=\"face\">\n" +
    "  <!-- adapted from https://codepen.io/davidrossiter/pen/mPgmMj-->\n" +
    "	<div class=\"face-eyeline\">\n" +
    "    <div class=\"face-eye--left\"></div>\n" +
    "    <div class=\"face-eye--right\"></div>\n" +
    "  </div>\n" +
    "  <div class=\"face-mouth\">\n" +
    "    <div class=\"face-tongue\"></div>\n" +
    "  </div>\n" +
    "  <div class=\"face-blushes\">\n" +
    "    <div class=\"face-blush--left\"></div>\n" +
    "    <div class=\"face-blush--right\"></div>\n" +
    "  </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/hello/messages.html",
    "<div>\n" +
    "  <div class=\"hello hello-full\" duration=\"1000\">\n" +
    "		Ciao!\n" +
    "  </div>\n" +
    "  <div class=\"hello hello-full\" duration=\"1000\">\n" +
    "		Hello!\n" +
    "  </div>\n" +
    "  <div class=\"hello hello-creature\" duration=\"1500\">\n" +
    "		<span>\n" +
    "			Hi friend!\n" +
    "			<div class=\"ball\">\n" +
    "		    <ng-include src=\"'views/directives/hello/face.html'\"></ng-include>\n" +
    "		  </div>\n" +
    "		</span>\n" +
    "  </div>\n" +
    "  <div class=\"hello hello-full hello-hydrate\" duration=\"1500\">\n" +
    "		<span>\n" +
    "			<div class=\"cup\">\n" +
    "				<div ng-include=\"'views/directives/hello/face.html'\"></div>\n" +
    "			</div>\n" +
    "			Don't forget to hydrate!\n" +
    "		</span>\n" +
    "  </div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/button.html",
    "<button class=\"history-button invert\" ng-if=\"configuration.viewOptions.changes.enabled\" ng-class=\"{ added: history.added, removed: history.removed }\" ng-show=\"history.changes.length > 0\" ng-click=\"toggleHistory()\">\n" +
    "	View Edits\n" +
    "</button>\n" +
    "");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/collection-item-history.html",
    "<div class=\"question\" history=\"collectionItemHistory\" button-location=\"collectionItemHistoryButtonLocation\">\n" +
    "	<div class>\n" +
    "  	<span class=\"\">{{change.name.value}}</span>\n" +
    "	</div>\n" +
    "	View individual field changes by expanding this item. \n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/field-history.html",
    "<div history=\"fieldHistory\" button-location=\"fieldHistoryButtonLocation\">\n" +
    "	<!-- \n" +
    "	todo: any smarter way to do this parent lookup? \n" +
    "	 ngIf -> fieldHistory -> history -> ngRepeat -> historyItem\n" +
    "	-->\n" +
    "	<div class=\"field-wrapper\" field=\"$parent.$parent.$parent.$parent.$parent.definition\">\n" +
    "		<div ng-disabled=\"true\" field-history-value></div>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/historic-value.html",
    "<div class=\"historic-value\">\n" +
    "	<div class=\"field-panel-title\">\n" +
    "		<div class=\"title author\">{{historicValue.whoDunnit}}</div>\n" +
    "		<div class=\"subtitle date\">{{historicValue.timestamp | date : 'EEEE, MMM d, y h:mm a'}}</div>\n" +
    "		<div class=\"heading\" ng-if=\"historicValue.added\">created</div>\n" +
    "		<div class=\"heading\" ng-if=\"historicValue.removed\">removed</div>\n" +
    "	</div>\n" +
    "	<div class=\"field-panel-content\">\n" +
    "		<ng-transclude/>\n" +
    "	</div>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/history.html",
    "<div class=\"history field-panel\" ng-if=\"showFieldChanges && configuration.viewOptions.changes.enabled\" ng-form=\"historyForm\" novalidate isolate-form>\n" +
    "	<ul>\n" +
    "		<li class=\"field-panel-item\" ng-repeat=\"change in history.changes | orderBy: changeDate : true\">\n" +
    "			<div historic-value=\"change\"></div>\n" +
    "		</li>\n" +
    "		<li class=\"field-panel-item\" ng-show=\"!change.added\">\n" +
    "			<div historic-value=\"{ change: history.priorValue, timestamp: 'Previously' }\"></div>\n" +
    "		</li>\n" +
    "	</ul>\n" +
    "</div>");
}]);
})();

(function(module) {
try { module = angular.module("templates-tailor"); }
catch(err) { module = angular.module("templates-tailor", []); }
module.run(["$templateCache", function($templateCache) {
  "use strict";
  $templateCache.put("views/directives/history/question-history.html",
    "<div class=\"question\" history=\"questionHistory\" button-location=\"questionHistoryButtonLocation\">\n" +
    "	<div class=\"question-row\">\n" +
    "  	<span class=\"question-title\">{{change.question.text}}</span>\n" +
    "	</div>\n" +
    "	<div class=\"question-row\">\n" +
    "    <span class=\"clarification\">{{change.question.clarification}}</span>\n" +
    "	</div>\n" +
    "	<div class=\"question-row\" ng-if=\"change.question.answerType == 'choice'\">\n" +
    "		<label>Choices</label>\n" +
    "    <ul class=\"choices\" ng-form name=\"choicesForm\">\n" +
    "  		<li class=\"choice\" ng-repeat=\"choice in change.question.Choices\">\n" +
    "		    <span class=\"applying\">\n" +
    "		      <input\n" +
    "		        type=\"checkbox\"\n" +
    "		        id=\"{{'choice' + choice.id}}\"\n" +
    "		        class=\"checkbox choice-value\"\n" +
    "		        ng-checked=\"change.answerIsSelected(choice)\"\n" +
    "		        ng-disabled=\"true\"/>\n" +
    "		        <label\n" +
    "		          class=\"eligibility-choice-label\"\n" +
    "		          for=\"{{'choice' + choice.id}}\"\n" +
    "		          >\n" +
    "		          {{choice.value}}\n" +
    "		        </label>\n" +
    "		    </span>\n" +
    "		  </li>\n" +
    "		</ul>\n" +
    "  </div>\n" +
    "  <div class=\"question-row\" ng-if=\"change.question.answerType == 'range'\">\n" +
    "    <label>Range</label>\n" +
    "    <div class=\"range\">\n" +
    "    	<span class=\"min-width-2\">\n" +
    "	      {{change.change.range.operator}}\n" +
    "	    </span>	    \n" +
    "	    <span>\n" +
    "	      <span class=\"min-width-2\">\n" +
    "	        {{change.change.range.min_value}}\n" +
    "	      </span>\n" +
    "	      <span ng-if=\"change.change.range.operator === 'between'\">\n" +
    "	        <span>and</span>\n" +
    "	        <span class=\"min-width-2\">\n" +
    "	        	{{change.change.range.max_value}}\n" +
    "	        </span>\n" +
    "	      </span>\n" +
    "	    </span>\n" +
    "	  </div>\n" +
    "  </div>\n" +
    "  <!-- todo\n" +
    "	  <div class=\"question-row\" ng-if=\"change.question.answer_type == 'zip'\">\n" +
    "	    <label>Zip</label>\n" +
    "	    <answer-zip class=\"answers\"></answer-zip>\n" +
    "	  </div>\n" +
    "	-->\n" +
    "</div>");
}]);
})();
